import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';





export default function SearchFilter({data, placeholder, name, onChange}) {

  
  return (
    <div>
    <Autocomplete
      onChange={onChange}
      options={data}
      getOptionLabel={(option) => option.label}
      popupIcon={<FilterAltOutlinedIcon/>}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} name={name} variant="standard" autoFocus ='true' 
        sx={{
          ".MuiAutocomplete-endAdornment": {
            top: 'auto'
          }
        }}
        />
      )}
     
    />
  </div>
    
  );

}

 