import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import settingsGear from "../assets/UHG_Operations_Navy.png";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import TripOriginRoundedIcon from '@mui/icons-material/TripOriginRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "#002677",
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: 20,
        margin: 20
    }
}));

const RxViewTooltip = props => {
    console.log("raw data: ", props.rowData);
    let assignedTo = props.rowData[45].trim();
    // console.log("multiRow: ", props.multiRow)
    // console.log("******** props.assigned ******", props.assigned)
    return (
        <LightTooltip
            arrow
            sx={{ "& .MuiTooltip-arrow": { color: "white" } }}
            placement="right"
            title={
                <>
                    <div>
                        <Button
                            id={props.rowData}
                            variant="none"
                            onClick={() => props.handleEditClick(props.rowData)}
                            startIcon={<EditIcon />}
                            disabled={props.multiRow}
                        >
                            Edit
                        </Button>
                    </div>

                    {/* {props.handleUnassignedClick !== undefined && ( */}
                        <div>
                            <Button
                                id={props.rowData}
                                variant="none"
                                onClick={() => props.handleUnassignedClick(props.rowData)}
                                startIcon={<TripOriginRoundedIcon />}
                                disabled={assignedTo === '' || props.multiRow}
                            >
                                Unassign
                            </Button>
                        </div>
                    {/* )} */}

                    {/* {props.handleFinalDispositionClick !== undefined && (
                        <div>
                            <Button
                                id={props.rowData}
                                variant="none"
                                onClick={() => props.handleFinalDispositionClick(props.rowData)}
                                startIcon={<MoreVertRoundedIcon />}
                            >
                                Final Disposition
                            </Button>
                        </div>
                    )} */}
                </>
            }
        //     </>
        //   }

        >
            <img width="27px" height="23px" src={settingsGear} alt="settings gear" />
        </LightTooltip>
    );
};
export default RxViewTooltip;
