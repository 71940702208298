import React, { useEffect, useState } from "react";
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { useForm } from "../controls/useForm"
import { makeStyles } from "@mui/styles";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { finalDisposition } from "../components/DropDownData"

const initialFValues = {
    dueDate: new Date(),
}

// Assigned to
// const finalDisposition = [
//     { label: 'No action required - appropriate denial', val: '01'},
//     { label: 'Subsequent approved claim', val: '02'},
//     { label: 'Subsequent rejected claim', val: '03'},
//     { label: 'Drug no longer needed', val: '04'},
//     { label: 'Resolved', val: '05'},
//     { label: 'No further action required - good faith attempt', val: '06'},
//     { label: 'Review not required', val: '07'},
// ];

export default function FinalDisposition(props) {
    
    const [finalDispositionCode, setFinalDispositionCode] = useState("");
    const [finalDispositionNote, setFinalDispositionNote] = useState("");

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,

    } = useForm(initialFValues, true);

    const finalDispositionCodeIndex = typeof props.summaryClaimData === 'object' ? finalDisposition.findIndex(x => x.val === props.summaryClaimData.finalDispositionCode) : null;

    useEffect(() => {
        // final dispo code
        if (props.chosenFinalDispositionCode !== '' && props.chosenFinalDispositionCode !== null){
            setFinalDispositionCode(props.chosenFinalDispositionCode.label)
            props.setChosenFinalDispositionCode(props.chosenFinalDispositionCode)
        }
        else if (typeof props.summaryClaimData === 'object' && finalDispositionCodeIndex !== -1){
            setFinalDispositionCode(finalDisposition[finalDispositionCodeIndex].label)
            props.setChosenFinalDispositionCode(finalDisposition[finalDispositionCodeIndex])
        }
        else {
            setFinalDispositionCode(null)
        }
        // final dispo notes
        if (props.chosenFinalDispositionNote !== '' && props.chosenFinalDispositionNote !== null){
            setFinalDispositionNote(props.chosenFinalDispositionNote)
            props.setChosenFinalDispositionNote(props.chosenFinalDispositionNote)
        }
        else if (typeof props.claimReviewNotes === 'object' && props.claimReviewNotes.length !== 0){
            const arr = props.claimReviewNotes.filter(x => x.noteType === "F")
            var str = ''
            arr.forEach(element => {
                str = str + element.notes
            });
            setFinalDispositionNote(str)
            props.setChosenFinalDispositionNote(str)
        }
        else {
            setFinalDispositionNote(null)
        }
    }, []);

    return (
        <Grid container  rowSpacing={10}>
            <Grid item xs={6}>
                <Controls.SelectSingle
                    data={finalDisposition}
                    placeholder="Final Disposition"
                    name="finalDisposition"
                    value={finalDispositionCode}
                    onChange={(e, value) => {
                        setFinalDispositionCode(value);
                        props.setChosenFinalDispositionCode(value)
                    }}
                />

                <Controls.Input
                    label="Final disposition notes"
                    multiline
                    name="finalDispositionNote"
                    value={finalDispositionNote}
                    onChange={(e) => {
                        setFinalDispositionNote(e.target.value);
                        props.setChosenFinalDispositionNote(e.target.value)
                    }}
                >
                </Controls.Input>

            </Grid>
        </Grid>

    );
}