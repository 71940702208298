import * as React from 'react';
import Controls from "../controls/Controls"

export default function RejectCodeFilter(props) {
    return (
       
    
                <Controls.SearchFilter
                    data={data}
                    placeholder="Reject Code Category"
                    onChange={(e, value) => {
                        props.setChosenRejectCategory(value)
                    }}
                >

                </Controls.SearchFilter>

    
    );
}

// Reject Category Codes present in current RxView Application
const data = [
    { label: 'Exception and Appeal' },
    { label: 'Government Programs' },
    { label: 'Compound' },
    { label: 'Missing Pharmacy Info' },
    { label: 'Incorrect Pharmacy Info' },
    { label: "Enrollment" },
    { label: "Refill too soon/DUR" },
    { label: "Product not Covered" },
    { label: "All Others" },
];
