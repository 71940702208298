import { gridColumnLookupSelector } from "@mui/x-data-grid";
import axios from "axios";
import { useQuery } from "react-query"


const FORMULARY_URL = process.env.REACT_APP_FORMULARY || "api/v1/rxview/formularyId"
export const QUERY_NAME_FORMULARYID = 'formularyId'


export const getAllFormularyId = async ({ queryKey }) => {
    const [_, carrierData] = queryKey
    let carrierStr = listToString(carrierData)
    const url = `${FORMULARY_URL}`
    const { data } = await axios.post(url,  {carrierId: carrierStr})
    const newData = data.map ((val) => {
        
    return {key:val}
    }) 
    console.log("Formulary ID- Service Call NEW DATA");
    console.log(newData);
    console.log("Formulary ID- Service Call");
    console.log(data);

    return (newData)
}

// converts list to string
function listToString(lst){
    let str = ''
    lst.forEach(element => {
      str = str + element.key.trim() + ","
    })
    str = str.slice(0, -1);
    console.log(str)
    return str
}

// export const useFormularyId = () => {

   
//     return useQuery([QUERY_NAME_FORMULARYID], () => getAllFormularyId(), {
//         staleTime: Infinity
        
//     })

// }