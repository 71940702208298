import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Divider, Stack } from '@mui/material';
import { Logo } from "../components/Logo"
import { Link as RouterLink } from 'react-router-dom'
import Account from './menu/Account';
import metadata from "../metadata.json"




export default function Footer() {


    return (
        <Box sx={{
            bottom: 0,
            left: 0,
            height: 40,
            position: "fixed",
            width: "100%",
            backgroundColor: '#002677',
            paddingTop: '7px',
            // marginTop: "-100px" 
            zIndex: 100
        }}>
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} mr={1} ml={2}>
                <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                    <Logo />
                </Box>
                <Typography variant="h6" sx={{ color: 'white' }}>RxClaim Next<b>Gen</b>   {`v${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} `} </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Account />
                <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'white' }} />
                <Typography variant="fontWeight" sx={{ color: 'white' }}>© {new Date().getFullYear()} Optum. All rights reserved.</Typography>
            </Stack>
        </Box>



    );
}