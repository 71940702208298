// import { createTheme } from "@material-ui/core/styles";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          icon: {
            color: "#002677"
          },
          root: {
            color: "#434448",
            fontFamily: "OptumSansRegular"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: 15,
            fontFamily: "OptumSansBold"
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: 15,
            fontFamily: "OptumSansRegular"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: 15,
            fontFamily: "OptumSansBold",
            color: "002677"
          },
          body: {
            color: "282A2E"
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#CCEBF6 !important"
            },
            "& .Mui-selected": {
              backgroundColor: "#CCEBF6 !important"
            }
          },

          head: {
            height: "15px"
          }
        }
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            backgroundColor: "white !important",
            marginLeft: "35px",
            marginRight: "35px"
          },
          rowSelected: {
            backgroundColor: "#CCEBF6 !important"
          }
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF"
          },
          headerCell: {
            backgroundColor: "#EFEFEE !important",
            fontFamily: "OptumSansBold",
            color: "002677"
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "relative"
          },
          fixedHeader: {
            backgroundColor: "#EFEFEE !important"
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "white",
          color: "#316BBE",
          fontSize: 11
        }
      },

      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            padding: "2px 8px 2px",
            maxHeight: "20px",
            fontSize: 12,
            maxWidth: "400px",
            whiteSpace: "noWrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // fontWeight: "bold",
            fontFamily: "OptumSansRegular",
            color: "#282A2E",            
          }
        }
      },

      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            marginTop: 14,
            fontSize: 13
          },

          displayedRows: {
            marginTop: 14,
            fontSize: 13
          }
        },
      },

      MUIDataTableJumpToPage: {
        styleOverrides: {
          caption: {
            fontSize: 13,
            fontWeight: 400
          }
        }
      }
    }
  });

export default getMuiTheme;
