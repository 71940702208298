import { QueryClient, QueryClientProvider } from "react-query"
import RejectedClaimsReviewMainPage from "./pages/RejectedClaimsReviewMainPage";
// import UserProvider from "./context/UserContext";

function App() {

  const queryClient = new QueryClient()
  return (
    <div className="RejectedClaimsReview">
      <header className="RejectedClaimsReview-header">

        <QueryClientProvider client={queryClient}>
          {/* <UserProvider>  */}
            <RejectedClaimsReviewMainPage />
          {/* </UserProvider> */}
        </QueryClientProvider>

      </header>
    </div>
  );
}

export default App;
