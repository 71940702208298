// react
import React from 'react'
import PropTypes from 'prop-types'
// mui
import { Box } from '@mui/material'
 
// ----------------------------------------------------------------------
 
Logo.propTypes = {
  sx: PropTypes.object
}
 
export  function Logo({ sx }) {
  return <Box component="img" src="/images/optum_rx.svg" sx={{height: 25, ...sx }} />
}
 
export  function OptumRxLogo({ sx }) {
  return <Box component="img" src="/images/logo-optumrx.svg" sx={{height: 25, ...sx }} />
}