import React, { useEffect, useState } from "react";
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { useForm } from "../controls/useForm"
import { makeStyles } from "@mui/styles";
import { getAssignedToList } from "../services/RxViewServiceAssignedTo";
import { getRoleMapping } from "../services/RxViewServiceRoleMapping";

const initialFValues = {
    dueDate: new Date(),
}

// Assigned to
const assignedToData = [

];

export default function Assignment(props) {

    const [assignedTo, setAssignedTo] = useState();

    useEffect(() => {
        // get list of all user for assigned to
        getRoleMapping(props.sessionId).then(function (result){
            getAssignedTo(result);
            return
        })
        if (typeof props.summaryClaimData === 'object'){
            setAssignedTo({label: props.summaryClaimData.assignTo.trim()})    
            props.setChosenAssignedTo({label: props.summaryClaimData.assignTo.trim()})
        } else {
            let fullName = sessionStorage.getItem("user-fullName")
            fullName = fullName.split(" ")
            let formatedFullName = fullName[1] + ", " + fullName[0]
            setAssignedTo({ label: formatedFullName })
            props.setChosenAssignedTo({ label: formatedFullName })
        }
    }, []);

    // function to get all user to populate assigned to dropdown 
    //  Shalin's Code
    // function getAssignedTo() {
    //     getAssignedToList().then(function (result) {
    //         console.log(result);
    //         var assignedToLst = result.reduce((assignedTo, assignedToVal) => {
    //             var splitLst = assignedToVal.split(",")
    //             assignedToData.push({ label: splitLst[1]+", "+splitLst[2] });
    //             return assignedTo;
    //         }, []);
    //         return;
    //     });
    // }

    //Changed by Shree

    function getAssignedTo(roleMappingData) {
        getAssignedToList(roleMappingData).then(function (result) {
            console.log("Old Assigned to Results",result);
            assignedToData.length = 0
            var assignedToLst = result.forEach((assignedToVal) => {
                console.log('Assigned to Value', assignedToVal)
                // var splitLst = assignedToVal.split(",")
                assignedToData.push({ label: assignedToVal.lastName ? assignedToVal.firstName ? assignedToVal.lastName + ", " + assignedToVal.firstName : null : null });
                // assignedToData.push({ label:assignedToVal.lastName +", "+assignedToVal.firstName });
                // return assignedTo;
            });
            console.log("data in Assigned To ", assignedToData)
            return;
        });
    }

    const validate = (fieldValues = values) => {

    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,

    } = useForm(initialFValues, true, validate);

    useEffect(() => {
        props.setChosenDueDate(values.dueDate)
    }, [values.dueDate]);

    return (
        <Grid container rowSpacing={10}>
            <Grid item xs={6}>
                <Controls.SelectSingle
                    data={assignedToData}
                    placeholder="Assigned to"
                    name="assignedTo"
                    value={assignedTo}
                    onChange={(e, value) => {
                        setAssignedTo(value);
                        props.setChosenAssignedTo(value)
                    }}
                />
                <Controls.DatePicker
                    name="dueDate"
                    label="Due Date"
                    value={values.dueDate}
                    onChange={handleInputChange}
                    error={errors.dueDate}
                />
            </Grid>
        </Grid>

    );
}