import { Box, Dialog, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import RxViewPageLoader from "../components/RxViewPageLoader";
import Controls from "../controls/Controls";
import { deleteReviews } from "../services/RxViewServiceSaveReview";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const useStyles = makeStyles(theme => ({


    contentHeader: {
        fontSize: 26,
        fontFamily: "OptumSansBold",
        paddingTop: "10px",
        paddingLeft: "190px",
        color: "#002677",

    },
    contentBody: {
        fontSize: 21,
        fontFamily: "OptumSansRegular",
        paddingTop: "60px",
        // paddingLeft: "100px",
        textAlign:"center",
        color: "#002677",

    },

    button: {

        paddingTop: "80px",

    },


}));

export default function EditRejClaimsReviewBulkUnassign(props) {
    const classes = useStyles();
    console.log("bulk assign screen");
    const { onClose, data, claimsData } = props;
    console.log("data: ", data);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const toasterConfig = {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    };

    const unassignClicked = () => {
        console.log("unassign clicked");
        console.log("data for delete: ", data);
        deleteReviews({
            claimIds: data.map((i) => {
                return claimsData[i].claimId
            })
        }).then(() => {
            toast.success(`${data.length} claims unassigned successfully.`, toasterConfig)
        }).catch(() => {
            toast.error("Error while unassigning claims!", toasterConfig)
        });
    };

    const goToPreviousPage = async () => {
        await new Promise((r) => setTimeout(r, 2000));
        navigate(-1);
    };



    return (
        <Dialog
            fullWidth
            // maxWidth="xs"
            open={true}
        // PaperProps={{ variant: "elevation14" }}
        >
            {/* <Box sx={{ height: 225 }}> */}


            <Grid container bgcolor="#D9F6FA" sx={{ height: '60px' }}>
                <Grid item xs={12}>
                    <Typography className={classes.contentHeader}>
                        Confirm Action
                    </Typography>
                </Grid>
            </Grid>


            <Grid
                container
                // style={{ gap: '0%' }}
                direction="row"
                justifyContent="center"
                alignItems="center"
               
            >
                <Grid item >
                    <WarningAmberOutlinedIcon
                        style={{ backgroundColor: "transparent", color: "#F5B700", fontSize:"100px", paddingTop:"10px" }}
                        // fontSize="large"

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.contentBody}>
                    {/* <WarningAmberOutlinedIcon
                        style={{ backgroundColor: "transparent", color: "#F5B700" }}
                        fontSize="large"

                    /> */}
                        Are you sure you want to unassign {props.data.length} claims?

                    </Typography>
                </Grid>
            </Grid>

            <Grid
                container
                style={{ gap: '0.01%', flexWrap: "wrap" }}
                direction="row"
                justifyContent="right"
                alignItems="flex-end" className={classes.button}
            >
                <Grid item>
                    <Controls.Button
                        text="UNASSIGN"
                        variant="contained"
                        autofocus
                        onClick={() => {
                            unassignClicked();
                            // goToPreviousPage();
                            setTimeout(() => {
                                onClose()
                            }, 2500)
                            setLoading(true);
                        }}
                    //className={classes.leftButton}
                    />
                </Grid>
                <Grid item>
                    <Controls.Button
                        text="CANCEL"
                        color="default"
                        onClick={() => {
                            onClose()
                        }}
                    // className={classes.rightButton}
                    />
                </Grid>
            </Grid>
            <ToastContainer/>
            {/* <RxViewPageLoader show={loading} /> */}
            {/* </Box> */}
        </Dialog>
    );
}
