import React, { useEffect } from 'react'
import axios from 'axios';
import { getUserSessionData } from '../../services/RxViewServiceSessionID';
import secureLocalStorage  from  "react-secure-storage";


export default function FetchLoginDetails(props) {
    let { sessionId, setSessionId } = props;
    let retrievedUserData;


    const getSessionIdFromURL = () => {

        let queryStringAfterSplit = sessionId.split('&');
        let authType = queryStringAfterSplit[2].split('=')[1]
        let tenantId = queryStringAfterSplit[3].split('=')[1]
        let queryParamsSplitCommas = queryStringAfterSplit[1].toString().split(',');
        const [prop, value] = queryParamsSplitCommas.toString().split('=');
        setSessionId({ sessionId: value, tenantId: tenantId });
        return { sessionId: value, authType, tenantId };
    }

    useEffect(() => {

        let { sessionId, authType, tenantId } = getSessionIdFromURL();
        setNextGenData(sessionId, authType, tenantId);

        secureLocalStorage.setItem("sessionId", {sessionId: sessionId, authType: authType, tenantId: tenantId});

    }, []);

    const setNextGenData = async (sessionId, authType, tenantId) => {

        return await axios.post("api/v1/rxview/nextgen", { sessionId, authType, tenantId });
    }

    const setSessionData = (extractedSessionId) => {


        console.log("Valid User");
        sessionStorage.setItem('user-name', JSON.stringify(retrievedUserData.given_name).replaceAll('"', ''));
        sessionStorage.setItem('user-fullName', JSON.stringify(retrievedUserData.name).replaceAll('"', ''));

    }

    const verifyLogin = (extractedSessionId) => {


        const data = {
            "session-id": extractedSessionId
        }
        getUserSessionData(data, extractedSessionId).then(function (axiosResult) {
            console.log("response.JSON:", {
                message: "User Session data received",
                data: JSON.stringify(axiosResult.data)
            });
            retrievedUserData = axiosResult.data['decodedIdToken'];
            console.log("Retrieved Data Non Local " + retrievedUserData);
            setSessionData(extractedSessionId);
        });



    }

    useEffect(() => {

        let extractedSessionId = getSessionIdFromURL();
        verifyLogin(extractedSessionId);
    }, []);

    return null
}

