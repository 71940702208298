import axios from "axios";
import resolvePromise from "./Resolve";


const getAxiosAuthClientGetConfig = (data, sessionId) => {
  let axiosConfig;
  // console.log('Data session-id ' + sessionId);
  // console.log("User Service Data2")
  // console.log(data)
  return (axiosConfig = {
    params: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
      "Session-ID": data['sessionId']

    }
  });
};


export const getUserSessionData = async (data, sessionId) => {
  console.log("Inside getUserSessionData " + data);
  console.log(data)
  let response = await resolvePromise(
    // axios.get("/auth-proxy-module/secure/user-profile", getAxiosAuthClientGetConfig(data, data['session-id']))

    axios.get("/secure/user-profile", getAxiosAuthClientGetConfig(data['session-id'], data['session-id']['sessionId']))

  );
  // console.log("Response for Session Id Data ", response)
  console.log("Decode data Current Logged In User :")
  console.log(response.data.decodedIdToken.given_name)
  return response;
};

