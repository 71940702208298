import axios from "axios";
import {  useQuery } from "react-query"
import { status, finalDisposition as finalDispositionData } from "../components/DropDownData"

const CLAIMS_URL = process.env.REACT_APP_REJ_CLAIMS || "api/v1/rxview/claimSearch"
export const QUERY_NAME_REJECTEDCLAIMS = 'rejctedClaims'

const getRejectClaimResults= async (searchFilter) => {
  console.log(searchFilter)
  console.log("React APP -- ", process.env.REACT_APP_REJ_CLAIMS )
  let carrierId = listToString(searchFilter.carrier)
  let accountId = listToString(searchFilter.account)
  let groupId = listToString(searchFilter.group)
  let formularyId = listToString(searchFilter.formularyId)
  let memberId = searchFilter.memberId
  let claimId = searchFilter.claimId
  let fromSubmitDate = searchFilter.fromSubmitDate
  let toSubmitDate = searchFilter.toSubmitDate

  const finalDispositionIndex = typeof searchFilter.finalDisposition === 'object' && searchFilter.finalDisposition !== null ? 
                                finalDispositionData.findIndex(x => x.label === searchFilter.finalDisposition.label) : null;
  
  let finalDisposition = finalDispositionIndex !== null && finalDispositionIndex !== -1 ? finalDispositionData[finalDispositionIndex].val : ''

  console.log("final disp index", finalDispositionIndex)
  console.log("final disp value ", finalDisposition)

  const statusIndex = typeof searchFilter.trackingStatus === 'object' && searchFilter.trackingStatus !== null ?
                      status.findIndex(x => x.label === searchFilter.trackingStatus.label) : null

  let trackingStatus = statusIndex !== null && statusIndex !== -1 ? status[statusIndex].val : ''

  let followUpDate = searchFilter.followUpDate
  let dueDate = searchFilter.dueDate
  
  // check if user has selected anyone from assigned to field 
  let isUnAssigned = true
  let assignedTo = null

  if (searchFilter.isAssigned) {
      assignedTo = searchFilter.assignedTo !== undefined ? searchFilter.assignedTo.label : ''  
      //   assignedTo = searchFilter.assignedTo !== null ? searchFilter.assignedTo.label : ''
      // assignedTo = searchFilter.assignedTo !== null ? searchFilter.assignedTo.label : ''  
      isUnAssigned = false 
  }
  
  const url = `${CLAIMS_URL}?unAssigned=${isUnAssigned}`
                                + (claimId ? `&rxClaimNumber=${encodeURIComponent(claimId)}` : '')
                                + (searchFilter.labelName ? `&labelName=${encodeURIComponent(searchFilter.labelName)}` : '')
                                + (memberId ? `&memberId=${encodeURIComponent(memberId)}` : '')
                                + (searchFilter.submitDate ? `&submitDate=${encodeURIComponent(searchFilter.submitDate)}` : '')
                                + (searchFilter.protecteddrug ? `&protecteddrug=${encodeURIComponent(searchFilter.protecteddrug)}` : '')
                                + (searchFilter.rejectCode ? `&rejectCode=${encodeURIComponent(searchFilter.rejectCode)}` : '')
                                + (searchFilter.rejectCodeDesc ? `&rejectCodeDesc=${encodeURIComponent(searchFilter.rejectCodeDesc)}` : '')
                                + (searchFilter.claimSequenceNumber ? `&claimSequenceNumber=${encodeURIComponent(searchFilter.claimSequenceNumber)}` : '')
                                + (searchFilter.claimStatus ? `&claimStatus=${encodeURIComponent(searchFilter.claimStatus)}` : '')
                                + (searchFilter.rxNumber ? `&rxNumber=${encodeURIComponent(searchFilter.rxNumber)}` : '')
                                + (searchFilter.claimFillDate ? `&claimFillDate=${encodeURIComponent(searchFilter.claimFillDate)}` : '')
                                + (searchFilter.protectedDrugClass ? `&protectedDrugClass=${encodeURIComponent(searchFilter.protectedDrugClass)}` : '')
                                + (searchFilter.quantity ? `&quantity=${encodeURIComponent(searchFilter.quantity)}` : '')
                                + (searchFilter.pharmacyName ? `&pharmacyName=${encodeURIComponent(searchFilter.pharmacyName)}` : '')
                                + (searchFilter.pharmacyPhone ? `&pharmacyPhone=${encodeURIComponent(searchFilter.pharmacyPhone)}` : '')
                                + (searchFilter.prescriberId ? `&prescriberId=${encodeURIComponent(searchFilter.prescriberId)}` : '')
                                + (searchFilter.prescriberIdQualifier ? `&prescriberIdQualifier=${encodeURIComponent(searchFilter.prescriberIdQualifier)}` : '')
                                + (searchFilter.productId ? `&productId=${encodeURIComponent(searchFilter.productId)}` : '')
                                + (searchFilter.productIdQualifier ? `&productIdQualifier=${encodeURIComponent(searchFilter.productIdQualifier)}` : '')
                                + (searchFilter.productKey ? `&productKey=${encodeURIComponent(searchFilter.productKey)}` : '')
                                + (searchFilter.ndcObsoleteDate ? `&ndcObsoleteDate=${encodeURIComponent(searchFilter.ndcObsoleteDate)}` : '')
                                + (searchFilter.strength ? `&strength=${encodeURIComponent(searchFilter.strength)}` : '')
                                + (searchFilter.dose ? `&dose=${encodeURIComponent(searchFilter.dose)}` : '')
                                + (searchFilter.daysSupply ? `&daysSupply=${encodeURIComponent(searchFilter.daysSupply)}` : '')
                                + (searchFilter.drugClass ? `&drugClass=${encodeURIComponent(searchFilter.drugClass)}` : '')
                                + (searchFilter.ndc ? `&ndc=${encodeURIComponent(searchFilter.ndc)}` : '')
                                + (searchFilter.paNumber ? `&paNumber=${encodeURIComponent(searchFilter.paNumber)}` : '')
                                + (searchFilter.compoundCode ? `&compoundCode=${encodeURIComponent(searchFilter.compoundCode)}` : '')
                                + (searchFilter.excludedDrug ? `&excludedDrug=${encodeURIComponent(searchFilter.excludedDrug)}` : '')
                                + (searchFilter.formularyTier ? `&formularyTier=${encodeURIComponent(searchFilter.formularyTier)}` : '')
                                + (searchFilter.umCode ? `&umCode=${encodeURIComponent(searchFilter.umCode)}` : '')
                                + (searchFilter.physicianId ? `&physicianId=${encodeURIComponent(searchFilter.physicianId)}` : '')
                                + (searchFilter.patientResidence ? `&patientResidence=${encodeURIComponent(searchFilter.patientResidence)}` : '')
                                + (carrierId ? `&carrierId=${encodeURIComponent(carrierId)}` : '')
                                + (accountId ? `&accountId=${encodeURIComponent(accountId)}` : '')
                                + (groupId ? `&groupId=${encodeURIComponent(groupId)}` : '')
                                + (assignedTo ? `&assignedTo=${encodeURIComponent(assignedTo)}` : '')
                                + (fromSubmitDate ? `&fromSubmitDate=${encodeURIComponent(fromSubmitDate)}` : '')
                                + (toSubmitDate ? `&toSubmitDate=${encodeURIComponent(toSubmitDate)}` : '')
                                + (formularyId ? `&formularyId=${encodeURIComponent(formularyId)}` : '')
                                + (finalDisposition ? `&finalDisp=${encodeURIComponent(finalDisposition)}` : '')
                                + (trackingStatus ? `&statusCode=${encodeURIComponent(trackingStatus)}` : '')
                                + (followUpDate ? `&followUpDate=${encodeURIComponent(followUpDate)}` : '')
                                + (dueDate ? `&dueDate=${encodeURIComponent(dueDate)}` : '')
                                + (searchFilter.esrdCode ? `&esrdCode=${encodeURIComponent(searchFilter.esrdCode)}` : '')
                                + (searchFilter.hospiceCode ? `&hospiceCode=${encodeURIComponent(searchFilter.hospiceCode)}` : '')
                                + (searchFilter.transplantCode ? `&transplantCode=${encodeURIComponent(searchFilter.transplantCode)}` : '')
                                + (searchFilter.tbEligibility ? `&tbEligibility=${encodeURIComponent(searchFilter.tbEligibility)}` : '')
                                + (searchFilter.paReasonCode ? `&paReasonCode=${encodeURIComponent(searchFilter.paReasonCode)}` : '')
                                + (searchFilter.MessageDescription ? `&MessageDescription=${encodeURIComponent(searchFilter.messageDescription)}` : '')
  
  console.log("URL >>>>>>>>>>>>>>", url)
  const { data } = await axios.get(url)
  console.log(data);
  return data
 
}

const getRejectClaimResultsPost= async (searchFilter) => {
  console.log(searchFilter)
  console.log("React APP -- ", process.env.REACT_APP_REJ_CLAIMS )
  let carrierId = listToString(searchFilter.carrier)
  let accountId = listToString(searchFilter.account)
  let groupId = listToString(searchFilter.group)
  let formularyId = listToString(searchFilter.formularyId)
  let memberId = searchFilter.memberId
  let claimId = searchFilter.claimId
  let fromSubmitDate = searchFilter.fromSubmitDate
  let toSubmitDate = searchFilter.toSubmitDate

  const finalDispositionIndex = typeof searchFilter.finalDisposition === 'object' && searchFilter.finalDisposition !== null ? 
                                finalDispositionData.findIndex(x => x.label === searchFilter.finalDisposition.label) : null;
  
  let finalDisposition = finalDispositionIndex !== null && finalDispositionIndex !== -1 ? finalDispositionData[finalDispositionIndex].val : ''

  console.log("final disp index", finalDispositionIndex)
  console.log("final disp value ", finalDisposition)

  const statusIndex = typeof searchFilter.trackingStatus === 'object' && searchFilter.trackingStatus !== null ?
                      status.findIndex(x => x.label === searchFilter.trackingStatus.label) : null

  let trackingStatus = statusIndex !== null && statusIndex !== -1 ? status[statusIndex].val : ''

  let followUpDate = searchFilter.followUpDate
  let dueDate = searchFilter.dueDate
  
  // check if user has selected anyone from assigned to field 
  let isUnAssigned = true
  let assignedTo = null

  if (searchFilter.isAssigned) {
      assignedTo = searchFilter.assignedTo !== undefined ? searchFilter.assignedTo.label : ''  
      //   assignedTo = searchFilter.assignedTo !== null ? searchFilter.assignedTo.label : ''
      // assignedTo = searchFilter.assignedTo !== null ? searchFilter.assignedTo.label : ''  
      isUnAssigned = false 
  }
  
  const url = `${CLAIMS_URL}`

  console.log("URL >>>>>>>>>>>>>>", url)
  const { data } = await axios.post(url, {
    fromSubmitDate: fromSubmitDate ? fromSubmitDate : null,
    toSubmitDate: toSubmitDate ? toSubmitDate : null,
    memberId: memberId ? memberId : null,
    carrierId: carrierId ? carrierId : null,
    accountId: accountId ? accountId : null,
    groupId: groupId ? groupId : null,
    rxClaimNumber: claimId ? claimId : null,
    rejectCode: searchFilter.rejectCode ? searchFilter.rejectCode : null,
    unAssigned: isUnAssigned,
    assignedTo: assignedTo ? assignedTo : null,
    statusCode: trackingStatus ? trackingStatus : null,
    finalDisp: finalDisposition ? finalDisposition : null,
    followUpDate: followUpDate ? followUpDate : null,
    dueDate: dueDate ? dueDate : null,
    formularyId: formularyId ? formularyId : null
  })

  console.log(data);
  return data
 
}


export const useClaimSearch = (page = 0, filters ={}) => {
  console.log(filters)
  return useQuery( [QUERY_NAME_REJECTEDCLAIMS, filters, page], () => getRejectClaimResultsPost({ ...filters, pageNumber: page }), {
      // keepPreviousData: true,
      staleTime: 30 * 1000, // 30 seconds
  })
}

// converts list to string
function listToString(lst){
  let str = ''
  lst.forEach(element => {
    str = str + element.key.trim() + ","
  })
  str = str.slice(0, -1);
  console.log(str)
  return str
}

