import React from 'react'
import { TextField } from  '@mui/material';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    input1: {
      height: 200
    }
    
  });

export default function Input(props) {
    const classes = useStyles();


    const { name, label, value,error=null, onChange, ...other } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            style={{ width: "55%"   }}
            size="small"
            className={classes.inout1}
    
            {...other}
            {...(error && {error:true,helperText:error})}
        />
    )
}