import React, { useEffect, useState } from "react";
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function RejectCode(props) {
    
    const [rejectCodesToDisplay, setRejectCodesToDisplay] = useState([]);
    const [chosenRejectCode, setChosenRejectCode] = useState([]);
    
    // whenever reject category is changed, this useEffect is called
    useEffect(() => {
        findRejectCodes()
    }, [props.chosenRejectCategory]);

    function findRejectCodes(){
        if (props.chosenRejectCategory !== null){
            // clear the chosen reject code whenever reject category is changed
            setChosenRejectCode([])
            props.setChosenRejectCode([])
            // Get reject codes based on category chosen
            if (props.chosenRejectCategory.label === 'Exception and Appeal'){
                filterRejectCodes(exceptionAndAppeal)
            }
            if (props.chosenRejectCategory.label === 'Government Programs'){
                filterRejectCodes(governmentPrograms)
            }
            if (props.chosenRejectCategory.label === 'Compound'){
                filterRejectCodes(compound)
            }
            if (props.chosenRejectCategory.label === 'Missing Pharmacy Info'){
                filterRejectCodes(missingPharmacyInfo)
            }
            if (props.chosenRejectCategory.label === 'Incorrect Pharmacy Info'){
                filterRejectCodes(incorrectPharmacyInfo)
            }
            if (props.chosenRejectCategory.label === 'Enrollment'){
                filterRejectCodes(enrollment)
            }
            if (props.chosenRejectCategory.label === 'Refill too soon/DUR'){
                filterRejectCodes(refillTooSoonDUR)
            }
            if (props.chosenRejectCategory.label === 'Product not Covered'){
                filterRejectCodes(productNotCovered)
            }
            if (props.chosenRejectCategory.label === 'All Others'){
                filterRejectCodes(allOthers)
            }
        }
        // if no category chosen set state to empty list
        else {
            setRejectCodesToDisplay([])
            setChosenRejectCode([])
            props.setChosenRejectCode([])
        }
    }

    // function that will filter reject codes based on category chosen
    function filterRejectCodes(categoryLst){
        const filteredByRejectCodeInCategory = props.claimInfo.filter(obj => {
            return categoryLst.includes(obj.rejectCode.trim());
        });
        const uniqueRejectCodes = [...new Set(filteredByRejectCodeInCategory.map(item => item.rejectCode))]
        var rejectCodeLst = uniqueRejectCodes.reduce((acc, car) => {
            acc.push({ key: car });
            return acc;
        }, []);
        setRejectCodesToDisplay(rejectCodeLst)
        if (chosenRejectCode.length === 0){
            getAllClaimsByCategory(rejectCodeLst)
        }
    }

    // function that will filter reject codes based on category chosen
    function getAllClaimsByCategory(rejectCodeLst){
        let result = rejectCodeLst.map(a => a.key.trim());
        const filteredByCategory = props.claimInfo.filter(obj => {
            return result.includes(obj.rejectCode.trim());
        });
        props.setClaimsData(filteredByCategory)
    }

    // filters all claims based on the code chosen and if category is chosen
    useEffect(() => {
        if (props.chosenRejectCategory !== null && chosenRejectCode.length !== 0){
            let result = chosenRejectCode.map(a => a.key.trim());
            const filteredByRejectCodeInCategory = props.claimInfo.filter(obj => {
                return result.includes(obj.rejectCode.trim());
            });
            props.setClaimsData(filteredByRejectCodeInCategory)
        }
    }, [chosenRejectCode]);
    
    // if chosen reject code is 0 then get all claims under the chosen category
    useEffect(() => {
        if (chosenRejectCode.length === 0){
            findRejectCodes()
        }
    }, [chosenRejectCode.length === 0]);

    return (
       
    
        // <Grid container>
            
        //     <Grid item md={6}>
                <Controls.SelectMultiple
                
                    dropDownOptions={rejectCodesToDisplay}
                    onChange={(e, value) => {
                        setChosenRejectCode(value)
                        props.setChosenRejectCode(value)
                    }}
                    label="Reject Codes"
                    value={chosenRejectCode}
                    popupIcon={<FilterAltOutlinedIcon/>}
                >

                </Controls.SelectMultiple>

        //     </Grid>
                 
        // </Grid>
    );
}
// Reject Codes 
const dropDownData = [
    { key: ' 83', val: '83' },
    { key: ' 51', val: '51' },
    { key: ' 85', val: '85' },
];

const exceptionAndAppeal = ['3R','3W','3Y','569','608','60','61','615','616','645','66','7X','70','71','72','75',
                            '76','77','79','816','817','818','828','829','83','84','85','88','9T','922','923','924',
                            '925','926','927','928'];
                
const governmentPrograms = ['252','253','254','291','620','621','646','773','777','80','820','823','929','930','612','613'];

const compound = ['1W','290','6V','772','8G','8H','9M','9N'];

const enrollment = ['65','67','68','69','609','622','819','52'];

const refillTooSoonDUR = ['79','88'];

const productNotCovered = ['70'];

const missingPharmacyInfo = ['0','1','2','3','4','5','6','7','8','9','1C','1E','1T','10','11','12','13','14','15','16','17',
                            '18','19','2A','2B','2C','2D','2E','2G','2H','2J','2K','2M','2N','2P','2Q','2R','2S','2T','2U',
                            '2V','2W','2X','2Z','20','21','22','23','234','235','24','25','26','28','29','3A','3B','3C','3D',
                            '3M','3N','3Q','3S','3U','3V','30','32','33','34','35','36','38','39','4B','4C','4D','4E','4G','4H',
                            '4J','4K','4X','5C','5E','5J','5C','5E','5J','567','6C','6D','6E','6K','6M','6N','6P','6Q','6T','623',
                            '624','625','627','628','629','630','631','632','633','7C','7E','8C','8E'];

const incorrectPharmacyInfo = ['40','41','42','43','44','46','50','51','52','53','54','55','555','559','56','57','58','580','59','597',
                                '598','599','6X','6Y','6Z','600','601','602','603','604','605','606','607','614','617','62','634','635',
                                '637','638','639','64','640','641','642','643','644','42','43','44','46','543','7A','7D','7G','7H','7J',
                                '7K','7M','7N','7Q','7R','7S','7W','73','74','78','8R','8V','8Z','81','82','824','825','826','827','86',
                                '87','9E','9G','9H','9U','9V','9X','647','648','649','650','7T','769','770','774','8W','821','830','831'];

const allOthers = ['**','AA','AC','AD','AE','AF','AG','AJ','AK','AM','AQ','A1','A2','A3','A4','A5','A6','A7','A9','BE','BF','BH','BK','BM',
                    'B2','C*','CA','CB','CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL','CM','CN','CO','CP','CQ','CR','CT','CW','CX','CY','CZ','D*','DC','DN',
                    'DP','DQ','DR','DS','DT','DU','DV','DW','DX','DY','DZ','E*','EA','EB','EC','ED','EE','EF','EG','EH','EJ','EK','EM','EN','EP','ER','ET','EU',
                    'EV','EW','EX','EY','EZ','E1','E2','E3','E4','E5','E6','E7','E8','E9','FO','GE','G1','G2','G4','G5','G6','G7','G8','G9','HA','HB','HC','HD',
                    'HE','HF','HG','H1','H2','H3','H4','H5','H6','H7','H8','H9','JE','J9','KE','K5','M*','ME','MG','MH','MJ','MK','MM','MN','MP','MS','MT','MU',
                    'MV','MW','MX','MZ','M1','M2','M3','M4','M5','M6','M7','M8','NA','NC','ND','NE','NF','NG','NH','NJ','NK','NM','NP','NQ','NR','NU','NV','NW',
                    'NX','NY','N1','N3','N4','N5','N6','PA','PB','PC','PF','PH','PJ','PK','PM','PN','PP','PQ','PR','PS','PT','PU','PV','PW','PX','PY','PZ','P0',
                    'P2','P4','P5','P7','P8','RA','RB','RC','RD','RE','RF','RG','RH','RJ','RK','RM','RN','RP','RQ','RR','RS','RT','RU','RV','RW','RX','RY','RZ',
                    'R0','R1','R2','R3','R4','R5','R6','SA','SB','SC','SD','SE','SF','SG','SH','SJ','SK','SM','SN','SP','SQ','SW','S0','S1','S2','S3','S4','S5',
                    'S7','S8','S9','TD','TE','TF','TH','TJ','TK','TR','TS','TT','TU','TV','TW','TX','TY','TZ','T2','T3','UA','UE','UZ','U0','U7','VA','VC','VE',
                    'V0','WE','W0','W6','W7','W8','W9','XE','X3','X4','X5','X6','X8','YB','YC','YD','YJ','YK','YM','YN','YP','YQ','YR','YS','YT','YU','YV','YW',
                    'YX','YY','YZ','Y0','Y1','Y2','Y3','Y4','Y5','Y6','Y7','Y9','ZB','ZE','ZF','ZK','ZM','ZN','ZP','ZQ','ZX','ZY','Z0','Z1','Z2','Z3','Z4','Z5',
                    'Z8','Z9','1V','267','27','286','287','288','289','292','31','310','311','312','313','314','328','329','330','331','332','342','343','344',
                    '345','346','347','348','349','350','352','374','378','379','4M','4N','4P','4R','4W','4Y','4Z','568','570','572','832','876','877','878','879',
                    '889','89','890','9C','90','91','92','94','95','96','97','98','99','610','611','618','619','63'];

