import React from 'react'
import {  makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1.5),
        color: 'white',
        backgroundColor: '#002677',
        float: "center",
        position: "relative",

    },
 

}))



export default function Buttons(props) {

    const { text, size, color, variant, onClick, ...other } = props
    const classes = useStyles();

    return (
       
            <Button 
                style={{maxWidth: '150px', maxHeight: '150px', minWidth: '90px', minHeight: '40px'}}
                variant={variant || "contained"}
                size="large"
                onClick={onClick}
                {...other}
                classes={{ root: classes.root, label: classes.label }}>
                {text}
            </Button>
     
    )
}