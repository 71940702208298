import React, { useState, useEffect } from "react";
import Controls from "../controls/Controls";
import { useForm, Form } from "../controls/useForm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle, makeStyles } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
// import { editClaim} from "../services/RxViewService";
import RxViewPageLoader from "../components/RxViewPageLoader";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Container from "@mui/material/Container";
import { Grid, Paper } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Toolbar } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tracking from "./Tracking";
import Summary from "./Summary";
import { withStyles } from "@material-ui/core/styles";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ExpandMore } from '@material-ui/icons';
import Assignment from "./Assignment";
import FinalDisposition from "./FinalDisposition";
import { getRejectMsg } from "../services/RxViewServiceRejectMsg";
import { saveReviewBulk, getClaimSummaryDetail, updateReviewBulk } from "../services/RxViewServiceSaveReview";
// import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { saveClaimNotes, deleteClaimNotes, getClaimReviewNotes, saveBulkClaimNotes, deleteAndSaveBulkClaimNotes } from "../services/RxViewServiceClaimNotes";





const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: "10px",
        color: "#002677"
    },
    leftBtn: {
        margin: theme.spacing(1.5),
        left: "40%",
    },
    rightBtn: {
        left: "40%",
    },
    ClaimsInfo: {
        // margin: theme.spacing(3),      
        // paddingRight: "40px",
        // display: 'inline-flex',
        fontSize: 13,
        fontFamily: "OptumSansRegular",
        fontWeight: 1000,
        // padding: "10px",
    },
    value: {
        // marginLeft: theme.spacing(1),
        fontSize: 13,
        fontFamily: "OptumSansRegular",
        // display: 'inline-flex',
        // padding: "15px",
        // alignItems: "center"
    },
    toastMessage: {
        fontSize: 13,
        fontFamily: "OptumSansRegular",
        fontWeight: 1000,
        color: "#002677"
    }


}));
const styles = {
    tab: {

        color: '#5A5A5A'
    },

}


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        borderRadius: '30px 0px 30px 0',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#EFEFEE',
        //   borderBottom: '1px solid #12738E',
        marginBottom: -1,
        color: '#666666',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);


const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const customId = "custom-id-yes";

export default function EditRejClaimsReviewBulkUpdate(props) {
    // console.log("props")
    // console.dir(props)
    const toasterConfig = {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,


    };
    const navigate = useNavigate();
    const classes = useStyles();
    const { onClose, data, claimsData, open, onSubmit } = props;
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [activeTab, setActiveTab] = useState('1');
    const handleTabType = (event, newValue) => setActiveTab(newValue);
    const [rejectMsg, setRejectMsg] = useState('');

    const [summaryClaimData, setSummaryClaimData] = useState();

    const [chosenAssignedTo, setChosenAssignedTo] = useState("");
    const [chosenActionCode, setChosenActionCode] = useState("");
    const [chosenActivityCode, setChosenActivityCode] = useState("");
    const [chosenFollowUpDate, setChosenFollowUpDate] = useState(new Date());
    const [chosenStatusCode, setChosenStatusCode] = useState("");
    const [chosenDueDate, setChosenDueDate] = useState(new Date());
    const [chosenFinalDispositionCode, setChosenFinalDispositionCode] = useState("");

    const [chosenActivityNote, setChosenActivityNote] = useState("")
    const [chosenFinalDispositionNote, setChosenFinalDispositionNote] = useState("")

    const multipleClaimIds = props.data.map((i) => {
        console.log("claimsData[i][1]")
        console.log(claimsData[i])
        return claimsData[i].claimId
    }).join(', ')

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        console.log(data)
    };


    const handleNavigateBackAPage = async () => {
        await new Promise((r) => setTimeout(r, 3000));
        navigate(-1);

    };

    const handleChangeIndex = (index) => {
        setActiveTab(index);

    };

    useEffect(() => {
        // get string of code msg
        // getRejectMsg(props.data[1], props.data[8]).then(function (result) {
        //     setRejectMsg(result)
        //     return;
        // });
        // get claim summary detail
        getClaimSummaryDetail(claimsData[data[0]].claimId).then(function (result) {
            setSummaryClaimData(result)
            return;
        });
    }, []);

    const submitClicked = () => {
        var d = new Date()
        var currTime = d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString()
        console.log(typeof currTime, currTime)
        //Adding try-catch block
        // if summaryClaimData is empty then do insert
        try {
            if (typeof summaryClaimData !== 'object') {

                let fullName = sessionStorage.getItem("user-fullName")
                fullName = fullName.split(" ")
                let formatedFullName = fullName[1] + ", " + fullName[0]

                var assignedTo = chosenAssignedTo.label === undefined ? formatedFullName : chosenAssignedTo.label

                saveReviewBulk({
                    claimIds: props.data.map((i) => {
                        return claimsData[i].claimId
                    }


                    ),
                    assignTo: assignedTo.substring(0,50).trim(),
                    actionCode: chosenActionCode.val !== undefined ? chosenActionCode.val : '',
                    activityCode: chosenActivityCode.val !== undefined ? chosenActivityCode.val : '',
                    followUpDate: formatDates(chosenFollowUpDate),
                    statusCode: chosenStatusCode.val !== undefined ? chosenStatusCode.val : '',
                    dueDate: formatDates(chosenDueDate),
                    finalDispositionCode: chosenFinalDispositionCode.val !== undefined ? chosenFinalDispositionCode.val : '',
                    addedBy: sessionStorage.getItem("user-name").substring(0,10).trim(),
                    addedDate: formatDates(new Date()),
                    addedTime: currTime,
                    addedByProgramName: "RxView"
                },

                ).then(() => {
                    toast.success(`Claim IDs assigned to ${assignedTo} saved successfully.`, toasterConfig,
                        {
                            toastId: customId
                        }
                    )

                })
                .catch(() => {
                    toast.error("Error while assigning claims!", toasterConfig, { delay: 100 });
                })

                if (chosenFinalDispositionNote !== ""){
                    saveBulkClaimNotes({
                        claimIds: props.data.map((i) => {
                            return claimsData[i].claimId
                        }),
                        noteType: "F", 
                        note: chosenFinalDispositionNote
                    },).then(() => {
                        toast.success(`Final disposition notes saved successfully.`, toasterConfig,
                            {
                                toastId: customId
                            }
                        )
                    })
                    .catch(() => {
                        toast.error("Error while saving final disposition notes!", toasterConfig, { delay: 100 });
                    })
                }
                if (chosenActivityNote !== ""){
                    saveBulkClaimNotes({
                        claimIds: props.data.map((i) => {
                            return claimsData[i].claimId
                        }),
                        noteType: "A", 
                        note: chosenActivityNote
                    },).then(() => {
                        toast.success(`Activity notes saved successfully.`, toasterConfig,
                            {
                                toastId: customId
                            }
                        )
                    })
                    .catch(() => {
                        toast.error("Error while saving activity notes!", toasterConfig, { delay: 100 });
                    })
                }
            }
            // else do update
            else {

                var assignedTo = chosenAssignedTo.label === undefined ? summaryClaimData.assignTo : chosenAssignedTo.label
                var statusCode = chosenStatusCode.val === undefined ? summaryClaimData.statusCode : chosenStatusCode.val
                var finalDispoCode = chosenFinalDispositionCode.val === undefined ? summaryClaimData.finalDispositionCode : chosenFinalDispositionCode.val
                var actionCode = chosenActionCode.val === undefined ? summaryClaimData.actionCode : chosenActionCode.val
                var activityCode = chosenActivityCode.val === undefined ? summaryClaimData.activityCode : chosenActivityCode.val
                
                updateReviewBulk({
                    claimIds: props.data.map((i) => {
                        return claimsData[i].claimId
                    }


                    ),
                    assignTo: assignedTo.substring(0,50).trim(),
                    actionCode: actionCode,
                    activityCode: activityCode,
                    followUpDate: formatDates(chosenFollowUpDate),
                    statusCode: statusCode,
                    dueDate: formatDates(chosenDueDate),
                    finalDispositionCode: finalDispoCode,
                    addedBy: sessionStorage.getItem("user-name").substring(0,10).trim(),
                    addedDate: formatDates(new Date()),
                    addedTime: currTime,
                    addedByProgramName: "RxView"
                },

                ).then(() => {
                    toast.success(`Claim IDs assigned to ${assignedTo} updated successfully.`, toasterConfig)

                })
                .catch(() => {
                    toast.error("Error while updating assigned claims!", toasterConfig);
                })

                if (chosenFinalDispositionNote !== ""){
                    deleteAndSaveBulkClaimNotes({
                        claimIds: props.data.map((i) => {
                            return claimsData[i].claimId
                        }),
                        noteType: "F", 
                        note: chosenFinalDispositionNote
                    }).then( () => {
                        toast.success(`Final Disposition notes for claims assigned to ${assignedTo} updated successfully.`, toasterConfig)
                    }). catch( () => {
                        toast.success("Error while updating final disposition notes!", toasterConfig)
                    })
                }
                if (chosenActivityNote !== ""){
                    deleteAndSaveBulkClaimNotes({
                        claimIds: props.data.map((i) => {
                            return claimsData[i].claimId
                        }),
                        noteType: "A", 
                        note: chosenActivityNote
                    }).then( () => {
                        toast.success(`Activity notes for claims assigned to ${assignedTo} updated successfully.`, toasterConfig)
                    }). catch( () => {
                        toast.success("Error while updating activity notes!", toasterConfig)
                    })
                }

            }
        } 
        catch (ex) {
            toast.error("Error while saving / updating claims!", toasterConfig);
        }
    }

    // formats the dates in correct format to send to backend
    function formatDates(date) {
        var today = date.toISOString().slice(0, 10);
        today = today.replaceAll('-', '')
        var year = today.slice(0, 4)
        var month = today.slice(4, 6)
        var day = today.slice(6, 8)
        var reformattedDate = year + month + day
        return reformattedDate
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={true}
            PaperProps={{ variant: "elevation14" }}
        >
            <Paper>
                {/* onSubmit={handleSubmit} ---- add this later in Form*/}
                <Form >
                    <DialogTitle className={classes.dialogTitle}>
                        <div style={{ display: "flex" }}>
                            <br></br>
                            <Typography variant="h6" component="div" style={{ flexGrow: 5 }}>
                                {/* Update Claim ID - {props.data.length} */}
                                Updating {props.data.length} Claim IDs

                            </Typography>
                        </div>
                    </DialogTitle>

                    <DialogContent
                        dividers
                        PaperProps={{
                            variant: "elevation14",
                            sx: { width: "150%", maxHeight: 300 }
                        }}
                    >


                        <Grid container spacing={3} >
                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>

                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} TransitionProps={{ unmountOnExit: true }}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMore />}

                                            >
                                                <Typography>Claim Information</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container>
                                                    <Grid item xs={2.5} >
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Member ID:
                                                        </Typography>

                                                        <br></br>

                                                        <Typography className={classes.ClaimsInfo}>
                                                            Submit Date:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Pharmacy Name:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Pharmacy Phone:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Physician ID(NPI):
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Reject Code:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Reject Code Desc:
                                                        </Typography>

                                                    </Grid>

                                                    <Grid item xs={4} >
                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                    </Grid>


                                                    <Grid item xs={3}>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            PA Number:
                                                        </Typography>

                                                        <br></br>

                                                        <Typography className={classes.ClaimsInfo}>
                                                            Claims Sequence #:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            UM (PA, ST, QL):
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Patient Residence Code:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Assigned To:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            ESRD Member:
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Hospice Member:
                                                        </Typography>

                                                    </Grid>

                                                    <Grid item xs={2.5}>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A

                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            {props.data[27] == null ? "N/A" : props.data[27]}
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>

                                                            N/A
                                                        </Typography>

                                                    </Grid>

                                                    <Grid container>
                                                        <Grid item xs={2.5}>
                                                            <br></br>
                                                            <Typography className={classes.ClaimsInfo}>
                                                                Reject Msg:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9.5}>
                                                            <br></br>
                                                            <Typography className={classes.value}>
                                                                N/A
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>

                                    </CardContent>

                                </Card>
                            </Grid>


                            <Grid item xs={6}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>

                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} TransitionProps={{ unmountOnExit: true }} >
                                            <AccordionSummary id="panel2d-header" expandIcon={<ExpandMore />}        >
                                                <Typography>Drug Information</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2}>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Label Name:
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.ClaimsInfo}>
                                                            Quantity:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Days Supply:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Strength:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Dose:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Form:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Drug Class:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>

                                                        <br></br>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Formulary Tier:
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.ClaimsInfo}>
                                                            NDC:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            NDC Obsolete Date:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Fill Date:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Protected Class Drug:
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Compound Drug (Y/N):
                                                        </Typography>
                                                        <br></br>
                                                        <Typography className={classes.ClaimsInfo}>
                                                            Excluded Drug (Y/N):
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                        <br></br>

                                                        <Typography className={classes.value}>
                                                            N/A
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>

                                    </CardContent>

                                </Card>
                            </Grid>
                        </Grid>


                        {/* Bottom half of the screen - Summary / Assignment / Tracking / Final Disposition */}

                        {/* <Grid container direction="column" justifyContent="flex-start" style={{ marginTop: "30px" }} > */}

                        <Container>

                            <TabContext value={activeTab}   >
                                <Grid container justifyContent="flex-end" md={12} lg={12} mx="auto" style={{ marginTop: "30px" }}>
                                    <AppBar
                                        position="relative"

                                    >

                                        <Tabs
                                            value={activeTab}
                                            onChange={handleTabType}
                                            variant="fullWidth"
                                            TabIndicatorProps={{ style: { background: '#002677' } }}
                                            inkBarStyle={{ background: 'blue' }}

                                        >
                                            <Tab label="SUMMARY" value="1" style={styles.tab} />
                                            <Tab label="ASSIGNMENT" value="2" style={styles.tab} />
                                            <Tab label="TRACKING" value="3" style={styles.tab} />
                                            <Tab label="FINAL DISPOSITION" value="4" style={styles.tab} />


                                        </Tabs>
                                    </AppBar>
                                </Grid>
                                <Paper
                                    elevation={4}
                                    style={{ marginTop: "10px" }}
                                    sx={{
                                        width: `100%`,


                                    }}>
                                    <TabPanel value="1" >
                                        <Summary summaryClaimData={summaryClaimData} bulkUpdate={true}/>

                                    </TabPanel>
                                    <TabPanel value="2" >
                                        <Assignment summaryClaimData={summaryClaimData} setChosenAssignedTo={setChosenAssignedTo} setChosenDueDate={setChosenDueDate}
                                                    sessionId={props.sessionId} />
                                    </TabPanel>


                                    <TabPanel value="3"  >
                                        <Tracking setChosenActionCode={setChosenActionCode} chosenActionCode={chosenActionCode} 
                                                    setChosenStatusCode={setChosenStatusCode} chosenStatusCode={chosenStatusCode}
                                                    setChosenActivityCode={setChosenActivityCode} chosenActivityCode={chosenActivityCode} 
                                                    setChosenFollowUpDate={setChosenFollowUpDate}
                                                    setChosenActivityNote={setChosenActivityNote} chosenActivityNote={chosenActivityNote}  />
                                    </TabPanel>

                                    <TabPanel value="4"  >
                                        <FinalDisposition setChosenFinalDispositionCode={setChosenFinalDispositionCode} chosenFinalDispositionCode={chosenFinalDispositionCode}
                                                            setChosenFinalDispositionNote={setChosenFinalDispositionNote} chosenFinalDispositionNote={chosenFinalDispositionNote}/>
                                    </TabPanel>

                                </Paper>
                            </TabContext>


                        </Container>

                        <Container>
                            <br></br> <br></br>
                            <Controls.Button
                                variant="contained"
                                autofocus
                                onClick={(e, value) => {
                                    submitClicked()
                                    // handleNavigateBackAPage();
                                    setTimeout(() => {
                                        onClose()
                                    }, 2500)
                                    setLoading(true);

                                }}
                                text="UPDATE"
                                className={classes.rightBtn}

                            />

                            <Controls.Button
                                text="CANCEL"
                                color="default"
                                onClick={(e, value) => {
                                    onClose()


                                }}
                                className={classes.leftBtn}
                            />
                            <RxViewPageLoader show={loading} />
                        </Container>

                    </DialogContent>
                    <DialogContent dividers></DialogContent>
                    <ToastContainer />
                </Form>
            </Paper>
            <ToastContainer />
        </Dialog>
    );


}