import axios from "axios";

const SAVE_REVIEW_URL =  "api/v1/rxview/postReview"
const UPDATE_REVIEW_URL =  "api/v1/rxview/modifyReview"
const GET_SUMMARY_REVIEW_URL =  "api/v1/rxview/claimSummaryDetail"
const GET_APPROACHING_DUEDATE_URL =  "api/v1/rxview/countOfDueDateApproaching"
const SAVE_REVIEW_BULK_URL = "api/v1/rxview/postReviews"
const UPDATE_REVIEW_BULK_URL = "api/v1/rxview/modifyReviews"
const DELETE_REVIEW_ONE_OR_BULK = "api/v1/rxview/deleteReview"

const getAxiosConfig = () => {
    let axiosConfig;
    return (axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept"
      }
    });
  };

// get the summary detail for the claim
export const getClaimSummaryDetail = async (claimId) => {
    const { data } = await axios.get(GET_SUMMARY_REVIEW_URL + (claimId ? `?claimId=${encodeURIComponent(claimId)}` : `?claimId=${encodeURIComponent('')}`))
    return data
}

// get approaching due date count of user 
export const getCountOfDueDateApproaching = async (assignTo) => {
    const { data } = await axios.get(GET_APPROACHING_DUEDATE_URL + (`?assignTo=${encodeURIComponent(assignTo).substring(0,50).trim()}`), getAxiosConfig())
    return data
}

// saves the review based on the person assigned to
export const saveReview = async (claimId, assignTo, actionCode, activityCode, followUpDate, statusCode,
    dueDate, finalDispositionCode, addedBy, addedDate, addedTime, addedByProgramName) => {
    await axios.post(SAVE_REVIEW_URL + (claimId ? `?claimId=${encodeURIComponent(claimId)}` : `?claimId=${encodeURIComponent('')}`)
        + (assignTo ? `&assignTo=${encodeURIComponent(assignTo).substring(0,50).trim()}` : `&assignTo=${encodeURIComponent('')}`)
        + (actionCode ? `&actionCode=${encodeURIComponent(actionCode)}` : `&actionCode=${encodeURIComponent('')}`)
        + (activityCode ? `&activityCode=${encodeURIComponent(activityCode)}` : `&activityCode=${encodeURIComponent('')}`)
        + (followUpDate ? `&followUpDate=${encodeURIComponent(followUpDate)}` : `&followUpDate=${encodeURIComponent('')}`)
        + (statusCode ? `&statusCode=${encodeURIComponent(statusCode)}` : `&statusCode=${encodeURIComponent('')}`)
        + (dueDate ? `&dueDate=${encodeURIComponent(dueDate)}` : `&dueDate=${encodeURIComponent('')}`)
        + (finalDispositionCode ? `&finalDispositionCode=${encodeURIComponent(finalDispositionCode)}` : `&finalDispositionCode=${encodeURIComponent('')}`)
        + (addedBy ? `&addedBy=${encodeURIComponent(sessionStorage.getItem("user-name").substring(0,10).trim())}` : `&addedBy=${encodeURIComponent('')}`)
        + (addedDate ? `&addedDate=${encodeURIComponent(addedDate)}` : `&addedDate=${encodeURIComponent('')}`)
        + (addedTime ? `&addedTime=${encodeURIComponent(addedTime)}` : `&addedTime=${encodeURIComponent('')}`)
        + (addedByProgramName ? `&addedByProgramName=${encodeURIComponent(addedByProgramName)}` : `&addedByProgramName=${encodeURIComponent('')}`))
    //    return Promise.reject(new Error('An Error occured while saving Assigned to Data'))
    }

// update the review based on the person assigned to
export const updateReview = async (claimId, assignTo, actionCode, activityCode, followUpDate, statusCode,
    dueDate, finalDispositionCode, addedBy, addedDate, addedTime, addedByProgramName) => {
    await axios.put(UPDATE_REVIEW_URL + (claimId ? `?claimId=${encodeURIComponent(claimId)}` : `?claimId=${encodeURIComponent('')}`)
        + (assignTo ? `&assignTo=${encodeURIComponent(assignTo).substring(0,50).trim()}` : `&assignTo=${encodeURIComponent('')}`)
        + (actionCode ? `&actionCode=${encodeURIComponent(actionCode)}` : `&actionCode=${encodeURIComponent('')}`)
        + (activityCode ? `&activityCode=${encodeURIComponent(activityCode)}` : `&activityCode=${encodeURIComponent('')}`)
        + (followUpDate ? `&followUpDate=${encodeURIComponent(followUpDate)}` : `&followUpDate=${encodeURIComponent('')}`)
        + (statusCode ? `&statusCode=${encodeURIComponent(statusCode)}` : `&statusCode=${encodeURIComponent('')}`)
        + (dueDate ? `&dueDate=${encodeURIComponent(dueDate)}` : `&dueDate=${encodeURIComponent('')}`)
        + (finalDispositionCode ? `&finalDispositionCode=${encodeURIComponent(finalDispositionCode)}` : `&finalDispositionCode=${encodeURIComponent('')}`)
        + (addedBy ? `&addedBy=${encodeURIComponent(sessionStorage.getItem("user-name").substring(0,10).trim())}` : `&addedBy=${encodeURIComponent('')}`)
        + (addedDate ? `&addedDate=${encodeURIComponent(addedDate)}` : `&addedDate=${encodeURIComponent('')}`)
        + (addedTime ? `&addedTime=${encodeURIComponent(addedTime)}` : `&addedTime=${encodeURIComponent('')}`)
        + (addedByProgramName ? `&addedByProgramName=${encodeURIComponent(addedByProgramName)}` : `&addedByProgramName=${encodeURIComponent('')}`))
}

//BULK UPDATE - SAVE RECORD/INSERT A NEW RECORD

// saves the review based on the person assigned to
export const saveReviewBulk = async (data) => {
    console.log("Save Bulk Update Post Data",data)
    await axios.post(SAVE_REVIEW_BULK_URL, data)
}

// Bulk update the review based on the person assigned to

export const updateReviewBulk = async (data) => {
    console.log("Update Bulk Update Post Data",data)
    await axios.put(UPDATE_REVIEW_BULK_URL, data)
}

export const deleteReviews = async (data) => {
    console.log("Delete Reviews Data", data)
    axios.delete(DELETE_REVIEW_ONE_OR_BULK, {data: data})
}