import React, { useEffect, useState } from "react";
import { Grid, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { Form } from "../controls/useForm"
import DialogContent from "@material-ui/core/DialogContent";
import { Container } from '@material-ui/core';
import { Typography } from '@mui/material';
import { getCarriersList, getAccountsList, getGroupsList } from "../services/RxViewServiceCAG";
import { getAllFormularyId } from "../services/RxViewServicesFormularyId";
import { useQuery } from "react-query"
import RxViewPageLoader from "../components/RxViewPageLoader";
import { useNavigate } from "react-router-dom";
import { useForm } from "../controls/useForm"
import { getAssignedToList } from "../services/RxViewServiceAssignedTo";
import { ToastContainer } from "react-toastify";
import { getRoleMapping } from "../services/RxViewServiceRoleMapping";

const assignedToData = [

];

const trackingStatusData = [
    { label: 'In Progress' },
    { label: 'Awaiting Response' },
    { label: 'Complete' },
    { label: 'Cancel' },
];

const finalDispositionData = [
    { label: 'No action required - appropriate denial'},
    { label: 'Subsequent approved claim'},
    { label: 'Subsequent rejected claim'},
    { label: 'Drug no longer needed'},
    { label: 'Resolved'},
    { label: 'No further action required - good faith attempt'},
    { label: 'Review not required no longer needed'},
];

var d = new Date();
d.setDate(d.getDate() - 1);

const initialFValues = {
    claimStartDate: d,
    claimEndDate: new Date(),
    followUpDate: null,
    dueDate: null,
    claimId: '',
}

function RejectClaimReview({ sessionId }) {

    const [carrierData, setCarrierData] = useState([]);
    const [accountData, setAccountData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const [chosenCarriers, setChosenCarriers] = useState([]);
    const [chosenAccounts, setChosenAccounts] = useState([]);
    const [chosenGroups, setChosenGroups] = useState([]);

    const [memberId, setMemberId] = useState('');
    // const [claimId, setClaimId] = useState('');

    const [chosenFormularyId, setChosenFormularyId] = useState([]);
    const [assignedTo, setAssignedTo] = useState([]);
    const [finalDisposition, setFinalDisposition] = useState([]);
    const [trackingStatus, setTrackingStatus] = useState([]);
    const [disableSearchButton, setDisableSearchButton] = useState(false);
    const [resetClicked, setResetClicked] = useState(false);
    const [initialRender, setInitialRender] = useState(true)

    const [carrierNeededMsg, setCarrierNeededMsg] = useState('')

    useEffect(() => {
        // get list of all carriers at first render
        getCarrier();
        getRoleMapping(sessionId.sessionId).then(function (result) {
            getAssignedTo(result);
            return
        })
    }, []);

    // function to get all carrier 
    function getCarrier() {
        getCarriersList(sessionId.sessionId, sessionId.tenantId).then(function (
            result
        ) {
            // console.log(result);
            var carrierLst = result.reduce((acc, car) => {
                acc.push({ key: car });
                return acc;
            }, []);
            setCarrierData(carrierLst);
            console.log(carrierLst);
            return;
        });
    }

    // function to get all user to populate assigned to dropdown 
    function getAssignedTo(roleMappingData) {
        getAssignedToList(roleMappingData).then(function (result) {
            console.log("Old Assigned to Results", result);
            assignedToData.length = 0
            assignedToData.push( {label: "All Assignees"})
            var assignedToLst = result.forEach((assignedToVal) => {
                console.log('Assigned to Value', assignedToVal)
                // var splitLst = assignedToVal.split(",")
                assignedToData.push({ label: assignedToVal.lastName ? assignedToVal.firstName ? assignedToVal.lastName + ", " + assignedToVal.firstName : null : null });
                // assignedToData.push({ label:assignedToVal.lastName +", "+assignedToVal.firstName });
                // return assignedTo;
            });
            console.log("data in Assigned To ", assignedToData)
            return;
        });
    }

    // when chosen carrier state is changed, this useEffect is called to reflect account dropdown
    useEffect(() => {
        getAccountsForCarrier()
        validateErrors()
    }, [chosenCarriers]);

    // function to get the accounts based on carriers chosen 
    function getAccountsForCarrier() {
        getAccountsList(chosenCarriers).then(function (result) {
            console.log(result);
            var accountLst = result.reduce((acc, car) => {
                acc.push({ key: car });
                return acc;
            }, []);
            setAccountData(accountLst)
            console.log(accountLst);
            return;
        });
    }

    // when chosen account state is changed, this useEffect is called to reflect group dropdown
    useEffect(() => {
        getGroupsForAccount()
    }, [chosenAccounts]);

    // function to get the accounts based on carriers chosen 
    function getGroupsForAccount() {
        getGroupsList(chosenCarriers, chosenAccounts).then(function (result) {
            console.log(result);
            var groupLst = result.reduce((acc, car) => {
                acc.push({ key: car });
                return acc;
            }, []);
            setGroupData(groupLst)
            console.log(groupLst);
            return;
        });
    }

    // navigates to search result page upon Search button clicked
    let navigate = useNavigate();
    const submitClicked = () => {
        // console.log(chosenCarriers, carrierData)
        let isAssignedVar = isAssigned()
        let path = `/searchResults`;
        navigate(path, {state:
                            {
                                carrier: chosenCarriers.length > 0 ? chosenCarriers : carrierData,
                                account: chosenAccounts,
                                group: chosenGroups,
                                memberId: memberId,
                                claimId: values.claimId,
                                fromSubmitDate: formatDates(values.claimStartDate),
                                toSubmitDate: formatDates(values.claimEndDate),
                                assignedTo: assignedTo,
                                formularyId: chosenFormularyId,
                                finalDisposition: finalDisposition,
                                trackingStatus: trackingStatus,
                                followUpDate: formatDates(values.followUpDate),
                                dueDate: formatDates(values.dueDate),
                                isAssigned: isAssignedVar,
                                sessionId: sessionId.sessionId
                            }
                        }
                );

    }


    function isAssigned() {

        let followUpDate = formatDates(values.followUpDate)
        let dueDate = formatDates(values.dueDate)

        if ( (!Array.isArray(assignedTo) && assignedTo != null) ||
            !Array.isArray(finalDisposition) ||
            !Array.isArray(trackingStatus) ||
            (followUpDate !== undefined && followUpDate !== '00000000') ||
            (dueDate !== undefined && dueDate !== '00000000')) {
            return true;
        }
        return false;
    }

    // formats the dates in correct format to send to backend
    function formatDates(date) {
        if (date === null) {
            return "00000000"
        }
        var today = date.toISOString().slice(0, 10);
        today = today.replaceAll('-', '')
        var year = today.slice(0, 4)
        var month = today.slice(4, 6)
        var day = today.slice(6, 8)
        var reformattedDate = year + month + day
        return reformattedDate
    }

    // const {  data:getFormularyId, isLoading } = useQuery(['getFormularyId'],() => getAllFormularyId())
    //cachetime is 5 minutes
    const { data: FormularyId, isLoading } = useQuery(['FormularyId', carrierData], getAllFormularyId, { staleTime: Infinity })
    //  ,{   cacheTime: 75 * 60 * 1000})

    console.log("Formulary ID Data in RxView Form")
    console.log(FormularyId)

    // validations for the date pickers
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('claimStartDate' in fieldValues) {
            if (fieldValues.claimStartDate === null) {
                temp.claimStartDate = "Start date can't be blank"
                temp.forOnlyInvalidFormat = ""
                setDisableSearchButton(true)
            }
            else if ((fieldValues.claimStartDate).toString() === "Invalid Date") {
                temp.claimStartDate = ""
                temp.forOnlyInvalidFormat = "Invalid date"
                setDisableSearchButton(true)
            }
            else if (values.claimEndDate !== null) {
                temp.forOnlyInvalidFormat = ""
                if (fieldValues.claimStartDate > values.claimEndDate.setHours(0, 0, 0, 0)) {
                    temp.claimStartDate = "Start date can't be greater than end date"
                    setDisableSearchButton(true)
                }
                else {
                    temp.claimStartDate = ""
                    if (values.claimEndDate.setHours(0, 0, 0, 0) >= fieldValues.claimStartDate) {
                        temp.claimEndDate = ""
                    }
                }
            }
            else {
                temp.forOnlyInvalidFormat = ""
                temp.claimStartDate = ""
            }
        }

        if ('claimEndDate' in fieldValues) {
            if (fieldValues.claimEndDate === null) {
                temp.claimEndDate = "End date can't be blank"
                temp.forOnlyInvalidFormat = ""
                setDisableSearchButton(true)
            }
            else if ((fieldValues.claimEndDate).toString() === "Invalid Date") {
                temp.claimEndDate = ""
                temp.forOnlyInvalidFormat = "Invalid date"
                setDisableSearchButton(true)
            }
            else if (values.claimStartDate !== null) {
                temp.forOnlyInvalidFormat = ""
                if (fieldValues.claimEndDate < values.claimStartDate.setHours(0, 0, 0, 0)) {
                    temp.claimEndDate = "End date can't be less than start date"
                    setDisableSearchButton(true)
                }
                else {
                    temp.claimEndDate = ""
                    if (values.claimStartDate.setHours(0, 0, 0, 0) <= fieldValues.claimEndDate) {
                        temp.claimStartDate = ""
                    }
                }
            }
            else {
                temp.forOnlyInvalidFormat = ""
                temp.claimEndDate = ""
            }
        }

        if ('claimId' in fieldValues) {
            temp.claimId = isNaN(fieldValues.claimId) ? "This field should be numeric" : fieldValues.claimId.length > 14 || fieldValues.claimId.length === 0 ? "" : "This field should have minimum 15 digits."
            setDisableSearchButton(true)
        }
        console.log(temp)
        setErrors({
            ...temp
        });
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, true, validate);

    useEffect(() => {
        console.log(initialRender)
        if (initialRender) {
            setInitialRender(false)
        }
        else {
            console.log("Reset clickeed")

            setErrors({})
            setValues(initialFValues)

            chosenCarriers.length = 0
            chosenAccounts.length = 0
            chosenGroups.length = 0
            chosenFormularyId.length = 0

            setTrackingStatus()
            setAssignedTo()
            setFinalDisposition()
            setMemberId('')

        }
    }, [resetClicked]);

    useEffect(() => {
        validateErrors()
    }, [errors]);

    useEffect(() => {
        validateErrors()
    }, [assignedTo, trackingStatus, finalDisposition, chosenFormularyId, memberId]);

    function validateErrors(){
        var errorFound = false
        // console.log("errors, ", errors)
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") {
                errorFound = true
            }
        });

        var carrierNeeded = true
        if (memberId !== '' || 
            values.claimId !== '' || 
            (!Array.isArray(assignedTo) && assignedTo !== null) || 
            chosenFormularyId.length !== 0 || 
            (!Array.isArray(finalDisposition) && finalDisposition !== null) || 
            (!Array.isArray(trackingStatus) && trackingStatus !== null) || 
            (values.followUpDate !== null && values.followUpDate.toString() !== 'Invalid Date') || 
            (values.dueDate !== null && values.dueDate.toString() !== 'Invalid Date')){
                carrierNeeded = false
                setCarrierNeededMsg("")
        }

        if (chosenCarriers.length !== 0){
            carrierNeeded = false
            setCarrierNeededMsg("")
        }

        if (carrierNeeded){
            setCarrierNeededMsg("Must pick at least 1 carrier to search")
        }

        if (errorFound || carrierNeeded){
            setDisableSearchButton(true)
        }
        else {
            setDisableSearchButton(false)
        }
    }

    return isLoading ? (

        <RxViewPageLoader show />
    ) : (
        <>

            <Form >
                <Grid container alignItems="center">
                    {/* <Grid item xs={12}>
                    <h6 className="rxViewHeader">Reject Claim Review</h6>
                </Grid>    */}
                    <br></br><br></br>
                    <Typography variant="h5" component="div" sx={{ color: '#002677' }} align='center' style={{ flexGrow: 5 }}>
                        Reject Claim Review
                    </Typography>
                </Grid>

                <DialogContent dividers PaperProps={{ variant: "elevation14", sx: { width: "100%", maxHeight: 400 } }} >
                    <Paper elevation={5}>
                        <Grid container>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                <Controls.SelectDropDown
                                    name="carrier"
                                    label="Carrier"
                                    dropDownOptions={carrierData}
                                    inputProps={{
                                        maxLength: 10,

                                    }}
                                    onChange={(e, value) => {
                                        setChosenCarriers(value);
                                    }}
                                />

                                <p style={{color: 'red', fontSize: '12px', textAlign: 'center', marginLeft: '100px', marginTop: '-10px', marginBottom: '0'}}>{carrierNeededMsg}</p>

                                <Controls.SelectDropDown
                                    name="accountID"
                                    label="Account"
                                    dropDownOptions={accountData}
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                    onChange={(e, value) => {
                                        setChosenAccounts(value);
                                    }}
                                />

                                <Controls.SelectDropDown
                                    name="groupID"
                                    label="Group"
                                    dropDownOptions={groupData}
                                    onChange={(e, value) => {
                                        setChosenGroups(value);
                                    }}
                                />

                                <Controls.Input
                                    inputProps={{
                                        maxLength: 18,
                                        style: { textTransform: "uppercase" }
                                    }}
                                    name="memberID"
                                    label="Member ID"
                                    value={memberId}
                                    onChange={(e) => {
                                        setMemberId(e.target.value);
                                    }}
                                />

                                <Controls.Input
                                    inputProps={{
                                        maxLength: 15,
                                    }}
                                    name="claimId"
                                    label="Claim ID"
                                    value={values.claimId}
                                    onChange={handleInputChange}
                                    error={errors.claimId}
                                />

                                <Controls.DatePicker
                                    name="claimStartDate"
                                    label="Claim Submission Start Date"
                                    value={values.claimStartDate}
                                    onChange={handleInputChange}
                                    error={errors.claimStartDate}
                                />

                                <Controls.DatePicker
                                    name="claimEndDate"
                                    label="Claim Submission End Date"
                                    value={values.claimEndDate}
                                    onChange={handleInputChange}
                                    error={errors.claimEndDate}
                                />

                            </Grid>

                            <Grid item xs={6}>

                                <Controls.SelectSingle
                                    data={assignedToData}
                                    placeholder="Assigned to"
                                    name="assignedTo"
                                    value={assignedTo}
                                    clearableIcon={Array.isArray(assignedTo) || assignedTo === undefined}
                                    onChange={(e, value) => {
                                        setAssignedTo(value);
                                    }}
                                />

                                <Controls.SelectDropDown
                                    name="formularyID"
                                    label="Formulary ID"
                                    dropDownOptions={FormularyId}
                                    onChange={(e, value) => {
                                        setChosenFormularyId(value);
                                    }}
                                />

                                <Controls.SelectSingle
                                    data={finalDispositionData}
                                    placeholder="Final Disposition"
                                    name="finalDisposition"
                                    value={finalDisposition}
                                    clearableIcon={Array.isArray(finalDisposition) || finalDisposition === undefined}
                                    // disabled={disableDropdownFlag}
                                    onChange={(e, value) => {
                                        setFinalDisposition(value);
                                    }}
                                />

                                <Controls.SelectSingle
                                    data={trackingStatusData}
                                    placeholder="Tracking Status"
                                    name="trackingStatus"
                                    value={trackingStatus}
                                    clearableIcon={Array.isArray(trackingStatus) || trackingStatus === undefined}
                                    // disabled={disableDropdownFlag}
                                    onChange={(e, value) => {
                                        setTrackingStatus(value);
                                    }}
                                />

                                <Controls.DatePicker
                                    name="followUpDate"
                                    label="Follow-up Date"
                                    value={values.followUpDate}
                                    onChange={handleInputChange}
                                //error={errors.followUpDate}
                                // disabled={disableDropdownFlag}
                                />

                                <Controls.DatePicker
                                    name="dueDate"
                                    label="Due Date"
                                    value={values.dueDate}
                                    onChange={handleInputChange}
                                //error={errors.dueDate}
                                // disabled={disableDropdownFlag}
                                />
                            </Grid>

                            <Container style={{ textAlign: "center" }}>
                                <Controls.Button
                                    // type="submit"
                                    variant="contained"
                                    disabled={disableSearchButton}
                                    onClick={(e, value) => {
                                        submitClicked()
                                    }}
                                    text="SEARCH"
                                />

                                <Controls.Button
                                    text="RESET"
                                    color="default"
                                    onClick={() => {
                                        setResetClicked(!resetClicked)
                                    }}
                                />
                            </Container>

                        </Grid>
                    </Paper>
                </DialogContent>

                {/* <ToastContainer /> */}
            </Form>
            <br></br>   <br></br>   <br></br>   <br></br>
            {/* <Footer /> */}

        </>

    );
}

export default RejectClaimReview;