// This filter component will have the option to search and select multiple values
import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { makeStyles } from "@mui/styles";
import { Grid, Paper } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { List, AutoSizer } from "react-virtualized";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />;
const checkedIcon = <CheckBoxIcon fontSize="inherit" />;
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  console.log("Item Count ",itemCount)
  const itemSize = 36;
  const listHeight = Math.min(itemCount * itemSize, 200)
  const listWidth = 505 / 1920 * window.innerWidth  
  console.log("width", window.innerWidth)
  return (
    <div ref={ref} >

      {/* // <div ref={ref} style={{ width: 300, height: 250 }}>  */}
      <div {...other}>
        <List 
            height={ listHeight}
          // height={200}
           width={listWidth}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
          // style={{ overflowY: "hidden" }}
          {...other}
        />
        {/* <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={itemSize}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={props => {
                return React.cloneElement(children[props.index], {
                  style: props.style
                  
                });
              }}
              role={role}
              overflowY="hidden"
            />
          )}
        </AutoSizer> */}
      </div>
    </div>
  );
});


const useStyles = makeStyles(
  {
    option:
    {
      fontSize: 15,
      "& > span":
      {
        marginRight: 3,
        fontSize: 18
      }
    }

  });
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.key,
});

export function SelectDropDown(props) {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const { label, name, dropDownOptions, onChange, error = null, value } = props



  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...dropDownOptions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (

    <Autocomplete
    
      multiple
      id="checkboxes-tags-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={onChange}
      // style={{ overflowY: "hidden" }}
      ListboxComponent={ListboxComponent}

      filterOptions={filterOptions}

      options={dropDownOptions}
      loading={loading}
      disableCloseOnSelect
      getOptionLabel={(option) => option.key}
      popupIcon={<SearchOutlinedIcon />}
  
      renderOption={(props, option, { selected }) => (

        <li {...props} key={option.key}>
          <Checkbox
            // icon={icon}                                     
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />

          {option.key}
        </li>

      )}

      {...(error && { error: true, helperText: error })}
      renderInput={(params) => (

        <TextField {...params} label={label} placeholder={label} name={name}
          sx={{
            ".MuiAutocomplete-endAdornment": {
              top: 'auto'
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />


      )}
    />



  )
}

//This function was created which has a text variant of standard and overide drop down icon with filter icon. Rest all functionalities remain same
export function SelectMultiple(props) {

  const { label, name, value, dropDownOptions, onChange, error = null } = props

  return (


    <Autocomplete
      multiple
      id="tags-standard"
      options={dropDownOptions}
      getOptionLabel={(option) => option.key}
      popupIcon={<FilterAltOutlinedIcon />}
      style={{ height: 15 }}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          // label="Reject Codes"
          placeholder={label}
          size="small"
          sx={{
            ".MuiAutocomplete-endAdornment": {
              top: 'auto'
            }
          }}
        />
      )}
    />


  )
}



// This function is created to handle single select
export function SelectSingle(props) {
  const { placeholder, name, data, onChange, value, disabled, clearableIcon, error = null } = props

  return (
    <Autocomplete
      // disablePortal
      disabled={disabled}
      options={data}
      onChange={onChange}
      value={value || null}
      disableClearable={clearableIcon}
      getOptionLabel={(option) => typeof option === "string" ? option : option.label || "" }
      isOptionEqualToValue={(option, value) => option.label === value.label}
      renderInput={(params) => {
        return (
          <TextField {...params} label={placeholder} name={name} variant="outlined"
            sx={{
              ".MuiAutocomplete-endAdornment": {
                top: 'auto'
              }
            }}

          />

        )
      }
      }




    />
  );

}