import axios from "axios";

const ROLEMAPPING_URL = process.env.REACT_APP_ROLEMAPPING || "api/v1/rxview/rolemapping"
export const QUERY_NAME_ROLEMAPPING = 'rolemapping'

export const getRoleMapping = async (sessionId) => {

    const url = `${ROLEMAPPING_URL}`
    const { data } = await axios.get(ROLEMAPPING_URL + `?sessionId=${encodeURIComponent(sessionId)}`);
    console.log("Role Mapping- Service Call");
    console.log(data);
    sessionStorage.setItem('role-mapping', JSON.stringify(data));
    // console.log("Get Role Mapping from Session Storage")
    // sessionStorage.getItem('role-mapping').forEach(element => console.log(element));
    return (data)

}