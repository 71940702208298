import React, { useEffect, useState } from "react";
import { Grid, Box, Paper } from '@material-ui/core';
import Controls from "../controls/Controls"
import { useForm } from "../controls/useForm"
import { makeStyles } from "@mui/styles";
import { status, action, activity } from "../components/DropDownData"

// // Status
// const status = [
//     { label: 'In Progress', val: '01'},
//     { label: 'Awaiting Response', val: '02' },
//     { label: 'Complete', val: '03' },
//     { label: 'Cancel', val: '00' },
// ];

// // Action
// const action = [
//     { label: 'Inform Member of DMR Process', val: '01' },
//     { label: 'Request COB review', val: '02' },
//     { label: 'Request formulary review', val: '03' },
//     { label: 'Request member eligibility review', val: '04' },
//     { label: 'Request adjudication/setup review (Note: with ORx would prompt us to create ticket in Service Now)' , val: '05'},
//     { label: 'Request operational override', val: '06' },
//     { label: 'Request PA (clinical review)', val: '07' },
//     { label: 'Request pharmacy network review', val: '08' },
//     { label: 'Requires outreach to member', val: '09' },
//     { label: 'Requires outreach to pharmacy' , val: '10'},
//     { label: 'Requires outreach to prescriber', val: '11' },
// ];

// // Activity
// const activity = [
//     { label: 'Outreach attempted (left message)', val: '01' },
//     { label: 'Outreach attempted (no answer)' , val: '02'},
//     { label: 'Outreach attempted (office closed)', val: '03' },
//     { label: 'Outreach attempted (phone note found)' , val: '04'},
//     { label: 'Outreach Completed' , val: '05'},
//     { label: 'Ticket entered in Service Now', val: '06' },
//     { label: 'Ticket closed in Service Now', val: '07' },
// ];

const initialFValues = {
    followUpDate: new Date(),
}

export default function Tracking(props) {

    const [actionCode, setActionCode] = useState("");
    const [activityCode, setActivityCode] = useState("");
    const [statusCode, setStatusCode] = useState("");
    const [activityNote, setActivityNote] = useState("");

    const date = new Date();

// date.setHours(0, 0, 0, 0);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('followUpDate' in fieldValues)
        temp.followUpDate = fieldValues.followUpDate < date.setHours(0, 0, 0, 0) ? "Follow Up Date can not be less than today's Date.":""

        console.log("followup"+fieldValues.followUpDate )
        console.log("new"+ new Date())
        setErrors({
            ...temp
        });
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
    } = useForm(initialFValues, true,validate);

    const statusCodeIndex = typeof props.summaryClaimData === 'object' ? status.findIndex(x => x.val === props.summaryClaimData.statusCode) : null;
    const actionCodeIndex = typeof props.summaryClaimData === 'object' ? action.findIndex(x => x.val === props.summaryClaimData.actionCode) : null;
    const activityCodeIndex = typeof props.summaryClaimData === 'object' ? activity.findIndex(x => x.val === props.summaryClaimData.activityCode) : null;

    useEffect(() => {
        // status code
        if (props.chosenStatusCode !== '' && props.chosenStatusCode !== null){
            setStatusCode(props.chosenStatusCode.label)
            props.setChosenStatusCode(props.chosenStatusCode)
        }
        else if (typeof props.summaryClaimData === 'object' && statusCodeIndex !== -1){
            setStatusCode(status[statusCodeIndex].label)
            props.setChosenStatusCode(status[statusCodeIndex])
        }
        else {
            setStatusCode(null)
        }
        // action code
        if (props.chosenActionCode !== '' && props.chosenActionCode !== null){
            setActionCode(props.chosenActionCode.label)
            props.setChosenActionCode(props.chosenActionCode)
        }
        else if (typeof props.summaryClaimData === 'object' && actionCodeIndex !== -1){
            setActionCode(action[actionCodeIndex].label)
            props.setChosenActionCode(action[actionCodeIndex])
        }
        else {
            setActionCode(null)
        }
        // activity code
        if (props.chosenActivityCode !== '' && props.chosenActivityCode !== null){
            setActivityCode(props.chosenActivityCode.label)
            props.setChosenActivityCode(props.chosenActivityCode)
        }
        else if (typeof props.summaryClaimData === 'object' && activityCodeIndex !== -1){
            setActivityCode(activity[activityCodeIndex].label)
            props.setChosenActivityCode(activity[activityCodeIndex])
        }
        else {
            setActivityCode(null)
        }
        // activity notes
        if (props.chosenActivityNote !== '' && props.chosenActivityNote !== null){
            setActivityNote(props.chosenActivityNote)
            props.setChosenActivityNote(props.chosenActivityNote)
        }
        else if (typeof props.claimReviewNotes === 'object' && props.claimReviewNotes.length !== 0){
            const arr = props.claimReviewNotes.filter(x => x.noteType === "A")
            var str = ''
            arr.forEach(element => {
                str = str + element.notes
            });
            setActivityNote(str)
            props.setChosenActivityNote(str)
        }
        else {
            setActivityNote(null)
        }
    }, []);

    // { label: 'Inform Member of DMR Process', val: '01' },
    useEffect(() => {
        props.setChosenFollowUpDate(values.followUpDate)
    }, [values.followUpDate]);

    return (

        // <> 
        <Grid container  rowSpacing={10}
 
        >
            <Grid item xs={6}>

                <Controls.DatePicker
                    name="followUpDate"
                    label="Follow Up Date"
                    value={values.followUpDate}
                    onChange={handleInputChange}
                    error={errors.followUpDate}

                />
                <Controls.SelectSingle
                    data={status}
                    placeholder="Status"
                    name="status"
                    value={statusCode}
                    onChange={(e, value) => {
                        setStatusCode(value);
                        props.setChosenStatusCode(value)
                    }}
                >
                </Controls.SelectSingle>

                <Controls.SelectSingle
                    data={action}
                    placeholder="Action"
                    name="action"
                    value={actionCode}
                    onChange={(e, value) => {
                        setActionCode(value);
                        props.setChosenActionCode(value)
                    }}
                >
                </Controls.SelectSingle>

            </Grid>

            <Grid item xs={6}>

                <Controls.SelectSingle
                    data={activity}
                    placeholder="Activity"
                    name="activity"
                    value={activityCode}
                    onChange={(e, value) => {
                        setActivityCode(value);
                        props.setChosenActivityCode(value)
                    }}
                >
                </Controls.SelectSingle>

                <Controls.Input
                    label="Activity Note"
                    multiline
                    name="activityNote"
                    value={activityNote}
                    onChange={(e) => {
                        setActivityNote(e.target.value);
                        props.setChosenActivityNote(e.target.value)
                    }}
                >
                </Controls.Input>

            </Grid>
            {/* </>  */}
        </Grid>

    );
}