import axios from "axios";

const SAVE_NOTE_URL =  "api/v1/rxview/saveClaimNote"
const SAVE_BULK_NOTE_URL =  "api/v1/rxview/saveClaimNotes"
const DELETE_SAVE_BULK_NOTE_URL =  "api/v1/rxview/deleteAndSaveClaimNotes"
const DELETE_NOTE_URL =  "api/v1/rxview/deleteClaimNote"
const GET_NOTE_URL =  "api/v1/rxview/claimNote"

// save the claim note
export const saveClaimNotes = async (claimId, noteType, notes) => {
    axios.post(SAVE_NOTE_URL, {claimId: claimId, noteType: noteType, notes: notes}) 
}

// save the claim note
export const saveBulkClaimNotes = async (data) => {
    await axios.post(SAVE_BULK_NOTE_URL, data) 
}

// delete and save new claim note
export const deleteAndSaveBulkClaimNotes = async (data) => {
    await axios.post(DELETE_SAVE_BULK_NOTE_URL, data) 
}

// delete the claim note
export const deleteClaimNotes = (data) => {
    axios.delete(DELETE_NOTE_URL, {data: data})
    // return res
}

// get the claim note
export const getClaimReviewNotes = async (claimId) => {
    const { data } = await axios.get(GET_NOTE_URL, {params: {claimId: claimId}})
    return data
}