import axios from "axios";

const REJECT_ASSIGNED_TO_URL = '/user-alias-service/search'

export const getAssignedToList = async (roleMappingData) => {
    console.log("Rejected assigned to url is ------- ", REJECT_ASSIGNED_TO_URL)
    let usersList = filterUserList(roleMappingData)
    const { data } = await axios.post(REJECT_ASSIGNED_TO_URL, {

        loginType: "ohid",
        userId: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        sub: null,
        roles:
            // [
            // "RXDV1_DEV_DG_ALL",
            // "RxClaim_Advanced_User",
            // "RXDV1_DEV_DG_Shree",
            // "RXDV1_DEV_DG_Shalin"
            usersList
        // ]
    })


    return data
}

function filterUserList(roleMappingData){
    const usersList = new Array();
    for (var k = 0; k < roleMappingData.length; k++) {
        usersList.push(roleMappingData[k])
    }
    return usersList
}