import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from '@material-ui/core';
export default function DatePicker(props) {

  const { name, label, value, disabled, error = null, onChange, disablePast} = props

  const convertToDefEventPara = (name, value) => ({
    target: {
      name, value
    }
  })

  return (
    <FormControl variant="outlined"
      {...(error && { error: true })}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker  variant="dialog" inputVariant="outlined"
          // style={{ width: "100%", marginLeft: "0%", height: "40px" }}
          style={{ width: "100%", marginLeft: "0%", marginTop: "0%"  }}
          label={label}
          format="MM/dd/yyyy"
          name={name}
          value={value}
          onChange={date => onChange(convertToDefEventPara(name, date))}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
