import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../assets/muitheme";
import { ThemeProvider } from "@mui/material/styles";
import RxViewPageLoader from "../components/RxViewPageLoader";
import { Typography, Stack } from '@mui/material';
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import RxViewTooltip from "../components/RxViewTooltip.js";
import Button from "@mui/material/Button";
import RejectCodeFilter from "./RejectCodeFilter";
import EditRejClaimsReview from "./EditRejClaimsReview";
import RejectCode from "./RejectCodes";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useClaimSearch } from "../services/RxViewService";
import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EditRejClaimsReviewBulkUpdate from "./EditRejClaimsReviewBulkUpdate"
import UpdateIcon from '@mui/icons-material/Update';
import EditRejClaimsReviewBulkUnassign from "./EditRejClaimsReviewBulkUnassign";
import { Replay } from "@material-ui/icons";
import { deleteReviews } from "../services/RxViewServiceSaveReview";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



function RejectClaimReviewSearchResultsTable({ route, navigation, props }) {

    const [claimsData, setClaimsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowsToSubmit, setSelectedRowsToSubmit] = useState([]);
    const [counter, setCounter] = useState(1);
    const [currentlySelectedRow, setCurrentlySelectedRow] = useState([]);
    const [showEditClaimDialogPage, setShowEditClaimDialogPage] = useState(false);
    const [showFinalDispositionDialogPage, setShowFinalDispositionDialogPage] = useState(false);
    const [refreshTableData, setRefreshTableData] = useState(false);
    const [chosenRejectCategory, setChosenRejectCategory] = useState("");
    const [chosenRejectCode, setChosenRejectCode] = useState([]);
    const [showView, setShowView] = useState(true);
    const [showEditClaimBulkDialogPage, setShowEditClaimBulkDialogPage] = useState(false);
    const [showBulkUnassignDialogPage, setShowBulkUnassignDialogPage] = useState(false);
    const [multipleSelectedRows, setMultipleSelectedRows] = useState([]);

    const [tableState, setTableState] = useState({});

    useEffect(() => {
        const storedData = localStorage.getItem('my-table-state');
        
        if (storedData) {
          setTableState(JSON.parse(storedData));
        }
      }, []);
    
    //console.log("multiple rows length", multipleSelectedRows.length > 1);

    const cogWheelColumn = {
        name: "",
        options: {
            filter: false,
            sort: true,
            empty: true,
            viewColumns: false,
            customBodyRender: (value, tableMeta) => {
                return (
                    <RxViewTooltip
                        rowData={tableMeta.rowData}
                        assigned={location.state.isAssigned}
                        multiRow={multipleSelectedRows.length > 1}
                        //handle single record click
                        handleEditClick={() => {
                            setCurrentlySelectedRow(tableMeta.rowData);
                            handleEditClick();
                            console.log("Row Data")
                            console.dir(tableMeta.rowData)
                            console.log("Row Index")
                            console.dir(tableMeta.rowIndex)
                        }}

                        handleUnassignedClick={() => {
                            setCurrentlySelectedRow(tableMeta.rowData);
                            handleUnassignClick(tableMeta.rowData);
                            console.log("Row Data for Unassign")
                            console.dir(tableMeta.rowData)
                            console.log("Row Index for Unassign")
                            console.dir(tableMeta.rowIndex)
                        }}
                    />
                );
            }
        }
    };

    const [columns, setColumns] = useState([

        cogWheelColumn,
        {
            name: "claimId",
            label: "Claim ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                // display:'excluded'
                viewColumns: false,
            }
        },
        {
            name: "accountId",
            label: "Account ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, [])
            }
        },
        {
            name: "carrierId",
            label: "Carrier ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, [])
            }
        },
        {
            name: "claimFillDate",
            label: "Claim Fill Date"
            ,
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
                sortThirdClickReset: true,
            }
        },
        {
            name: "claimSequenceNumber",
            label: "Claim Sequence Number",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "claimStatus",
            label: "Claim Status",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "compoundCode",
            label: "Compound Code",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "daysSupply",
            label: "Days Supply",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "dose",
            label: "Dose",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "drugClass",
            label: "Drug Class",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "esrdCode",
            label: "ESRD Code",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "excludedDrug",
            label: "Excluded Drug",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "formType",
            label: "Form Type",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "formularyId",
            label: "Formulary ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "formularyTier",
            label: "Formulary Tier",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "groupId",
            label: "Group ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "hospiceCode",
            label: "Hospice Code",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "protectedDrug",
            label: "Is Protected Drug",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                // display: true

            }
        },
        {
            name: "labelName",
            label: "Label Name",
            options: {
                filter: false,
                sort: true,
                // display: true,
                sortThirdClickReset: true
            }
        },
        {
            name: "memberId",
            label: "Member ID",
            options: {
                filter: false,
                sort: true,
                // display: true,
                sortThirdClickReset: true,
            }
        },
        {
            name: "tbEligibility",
            label: "Member TB Eligibility",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "ndc",
            label: "NDC",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "ndcObsoleteDate",
            label: "NDC Obsolete Date",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "paNumber",
            label: "PA Number",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "paReasonCode",
            label: "PA Reason Code",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "patientResidence",
            label: "Patient Residence",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "pharmacyName",
            label: "Pharmacy Name",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "pharmacyPhone",
            label: "Pharmacy Phone",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "physicianId",
            label: "Physician ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "prescriberId",
            label: "Prescriber ID",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "prescriberIdQualifier",
            label: "Prescriber ID Qualifier",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "productId",
            label: "Product ID",
            options: {  
                filter: false,
                sort: true,
                display: useEffect(() => { }, []),
                sortThirdClickReset: true,
            }
        },
        {
            name: "productIdQualifier",
            label: "Product ID Qualifier",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "productKey",
            label: "Product Key",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "protectedDrugClass",
            label: "Protected Drug Class",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "quantity",
            label: "Quantity",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "rejectCode",
            label: "Reject Code",
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
                // display: true
            }
        },
        {
            name: "rejectCodeDesc",
            label: "Reject Code Description",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                // display: true
            }
        },
        {
            name: "messageDescription",
            label: "Reject Message Description",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "rxNumber",
            label: "Rx Number",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "strength",
            label: "Strength",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "submitDate",
            label: "Submit Date",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                // display: true
            }
        },
        {
            name: "transplantCode",
            label: "Transplant Code",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "umCode",
            label: "UMCode",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
            }
        },
        {
            name: "assignedTo",
            label: "Assigned To",
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                display: useEffect(() => { }, []),
                // viewColumns: false,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                display: false,
                viewColumns: false,
                customBodyRender: value =>
                    <Button onClick={incrCount}>

                    </Button>
            }
        },
    ]);



    useEffect(() => {
        const arr = [...columns];
        arr[0] = cogWheelColumn;
        setColumns(arr);
        console.log("Inside Use Effect to Change Columns")
    }, [multipleSelectedRows < 2]);



    // const handleSaveTableViewClick = () => {
    //     toast.success(<Typography variant="h6">Column(s) saved successfully.</Typography>, toasterConfig);

    //     // localStorage.setItem((changedColumn, action))
    // }
    // const handleResetTableViewClick = () => {
    //     console.log("clicked on reset table view icon!");
    //     toast.success(<Typography variant="h6">Reject Claim Table reset successfully.</Typography>, toasterConfig)
    //     localStorage.clear();
    //     window.location.reload(true)
    // }

    const toasterConfig = {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,

    };

    const location = useLocation();

    const options = {
        // serverSide: true,
        sortFilterList: true,
        selectableRows: "multiple",
        selectableRowsHeader: true,
        selectToolbarPlacement: "replace",
        resizeableColumns: true,
        download: true,
        print: false,
        filterable: false,
        filter: false,
        pagination: true,
        viewColumns: 'true',
        filterType: "multiselect",
        responsive: "standard",
        search: true,
        storageKey: 'my-table-state',
        draggableColumns: {
            enabled: true
        },

        expandableRows: false,
        rowsPerPage: [50],
        rowsPerPageOptions: [50, 100, 150],
        jumpToPage: true,

        onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
            console.log("---RowSelect---");
            console.log("Current Row Selected: ", curRowSelected);
            console.log("All Selected: ", allRowsSelected);
            console.log("Rows Selected: ", rowsSelected);
            setSelectedRowsToSubmit(allRowsSelected);
            setMultipleSelectedRows(rowsSelected)

        },
        // onRowClick: handleRowClick,
        textLabels: {
            pagination: {
                next: "Next >",
                previous: "< Previous",
                rowsPerPage: "Rows per page"
            },
         
        },


        onColumnSortChange: (changedColumn, direction) => console.log('changedColumn: ', changedColumn, 'direction: ', direction),
        onTableChange: (action, tableState) => {
            console.log("On Table Change...Action");
            console.dir(action);
            console.dir("On Table Change...Table State");
            console.dir(tableState);
            console.log("--------------");
        
        },

        onViewColumnsChange: (changedColumn, action) => {
            console.log("onViewColumnsChange");
            console.log(changedColumn, action);
            console.log("columns")
            console.log(columns)
            window.localStorage.getItem(changedColumn, action)
            var newCols = columns.map(col => {
                // console.log(col);
                let newCol = Object.assign({}, col);
                // console.log(newCol);
                if (changedColumn === newCol.name) {
                    if (action === "add") {
                        newCol.options.display = true;
                        // window.localStorage.setItem(changedColumn, true)

                    } else {
                        newCol.options.display = false;
                        // window.localStorage.removeItem(changedColumn);
                    }
                }
                return newCol;
            });
            console.log("newCols")
            console.log(newCols)
            // console.log("columns")
            // console.log(columns)
            setColumns(newCols);

        },

        // to remove the delete icon from top during multiple rows select
        // customToolbarSelect: () => {},
        customToolbarSelect: selectedRows => {

            return multipleSelectedRows.length > 1 ? (

                <>
                    <Grid
                        container
                        style={{ gap: '0.5%' }}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                    // spacing={2}
                    xs={6}
                    >
                        <Grid item xs={3}>
                            <Tooltip title={location.state.isAssigned ? "Bulk Unassign" : "Disabled"}>
                                <span>
                                    <IconButton
                                        disabled={!location.state.isAssigned}
                                        onClick={handleBulkUnassignClick}
                                    >
                                        <Replay style={location.state.isAssigned ?
                                            { backgroundColor: "transparent", color: "#002677" } :
                                            { backgroundColor: "transparent", color: "gray" }} />
                                        <Typography style={location.state.isAssigned ?
                                            { backgroundColor: "transparent", color: "#002677" } :
                                            { backgroundColor: "transparent", color: "gray" }}>Bulk Unassign </Typography>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={3}>
                            <Tooltip title={"Bulk Update"}>
                                <IconButton
                                    onClick={handleEditBulkClick}
                                >
                                    <UpdateIcon
                                        style={{ backgroundColor: "transparent", color: "#002677" }}
                                    />
                                    <Typography style={{ backgroundColor: "transparent", color: "#002677" }}>Bulk Update </Typography>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </>
            ) : null;
        },


        customToolbar: () => (
            <>
                {/* <Tooltip title={"Save Table View"}>
                    <IconButton onClick={handleSaveTableViewClick}>
                        <SaveOutlinedIcon
                            style={{ backgroundColor: "transparent", color: "#002677" }}
                        />
                        <ToastContainer />
                    </IconButton>
                </Tooltip> */}

                {/* <Tooltip title={"Reset Table View"}>
                    <IconButton onClick={handleResetTableViewClick}  >
                        <RotateLeftIcon
                            style={{ backgroundColor: "transparent", color: "#002677" }}
                        />
                        <ToastContainer />
                    </IconButton>
                </Tooltip> */}
            </>
        ),
    };

    // const location = useLocation();
    // console.log("carrier", location.state.carrier)
    // console.log("account", location.state.account)
    // console.log("group", location.state.group)
    // console.log("member id", location.state.memberId)
    // console.log("claim id", location.state.claimId)
    // console.log("start date", location.state.fromSubmitDate)
    // console.log("end date", location.state.toSubmitDate)
    // console.log("assigned to ", location.state)

    const {
        status,
        data: rejectedClaims,
        error,


    } = useClaimSearch(0, {
        carrier: location.state.carrier,
        account: location.state.account,
        group: location.state.group,
        memberId: location.state.memberId,
        claimId: location.state.claimId,
        fromSubmitDate: location.state.fromSubmitDate,
        toSubmitDate: location.state.toSubmitDate,
        assignedTo: location.state.assignedTo,
        formularyId: location.state.formularyId,
        finalDisposition: location.state.finalDisposition,
        trackingStatus: location.state.trackingStatus,
        followUpDate: location.state.followUpDate,
        dueDate: location.state.dueDate,
        isAssigned: location.state.isAssigned
    });

  
    if (error) {
        return <p> An Error Occurred in fetching claim Data </p>  
    }


    const incrCount = () => {
        //We update a random value here to test table resizing on state updates
        setCounter(counter + 1);
    }


    const handleEditClick = rowData => {
        console.log("handle edit click " + rowData);

        setShowEditClaimDialogPage(true);
        // setCurrentlySelectedRow(rowData);


    };

    const handleEditBulkClick = rowData => {
        console.log("handle edit bulk click " + rowData);
        console.log("multipleSelectedRows", multipleSelectedRows)
        console.log("rejectedClaims", rejectedClaims)
        let bulkEditValid = true;
        for (let i=0; i < multipleSelectedRows.length; i++) {
            if (rejectedClaims[multipleSelectedRows[i]].assignedTo.trim().length > 0 && location.state.isAssigned === false) {
                bulkEditValid = false;
                toast.error(`Claims already in process Bulk Edit not allowed.`, toasterConfig)
                break;
            }
        }
        setShowEditClaimBulkDialogPage(bulkEditValid);

    };

    const handleUnassignClick = rowData => {
        console.log("handle unassigned click " + rowData);
        console.log("claimId: " + rowData[1]);
        deleteReviews({ claimIds: [rowData[1]] })
        .then( () => {
            toast.success(`Claim ${rowData[1]} unassigned successfully.`, toasterConfig)
            setTimeout(() => {
                window.location.reload();
            }, 2500)
        })
        .catch( () => {
            toast.error("Error while unassigning claim!", toasterConfig)
        });
    };


    const handleBulkUnassignClick = () => {
        console.log("handle edit bulk UNASSIGN");
        setShowBulkUnassignDialogPage(true);
    };

    const handleEditClaim = () => {
        // setRefreshTableData(true);
    };

    const handleCloseEdit = () => {
        setShowEditClaimDialogPage(false);
        window.location.reload();
    };

    const handleCloseBulkEdit = () => {
        setShowEditClaimBulkDialogPage(false);
        window.location.reload();
    };

    const handleCloseBulkUnassign = () => {
        setShowBulkUnassignDialogPage(false);
        window.location.reload();
    };

    const handleFinalDispositionClick = rowData => {
        setShowFinalDispositionDialogPage(true);
        setCurrentlySelectedRow(rowData);
    };

    const handleRowClick = (rowData, rowMeta) => {
        console.log("Selected Row Data");
        console.log(rowData, rowMeta);
    };

    return status === "loading" ? (
        <RxViewPageLoader show />
    ) : (
        <div class="disp-header-muiTable">
            <Grid container alignItems="center">
                <Grid item xs={12}>

                    <br></br>
                    <Typography variant="h5" component="div" sx={{ color: '#002677' }} align='center' style={{ flexGrow: 5 }}>
                        Reject Claim Search Results
                    </Typography>
                </Grid>
            </Grid>

            <Divider style={{ width: "100%" }} />





            {/* <Box sx={{ flexGrow: 1 }}> */}
                <Grid container justifyContent="left" pt={4} direction="row" style={{ gap: '2%', flexWrap: "wrap" }}>
                    <Grid item xs={2} style={{ marginLeft: "35px" }}>
                        <RejectCodeFilter setChosenRejectCategory={setChosenRejectCategory} />
                    </Grid>

                    <Grid item xs={2} style={{  textAlign: "left" , flexWrap: "wrap"}}>
                        <RejectCode chosenRejectCategory={chosenRejectCategory} setChosenRejectCode={setChosenRejectCode} claimInfo={rejectedClaims} setClaimsData={setClaimsData} />
                    </Grid>


                    {/* <Grid item
                        xs={2}
                        // style={{ marginLeft: "85%" , marginTop: "12px"}}
                        style={{ textAlign: "right" }}
                    >
                        <SaveTableView
                            className="addButtonText"
                            // clickHandler={handleSaveTableViewIconClick}
                            name="Save Table View"
                        // onClick={() => {
                        //     console.log("hi I am in Save Tbale View")
                        //   }}
                        />

                    </Grid>

                    <Grid item
                        xs={2}
                        style={{ textAlign: "right", marginRight: "35px" }}

                    >
                        <ResetTableView
                            className="addButtonText"
                            // clickHandler={handleSaveTableViewIconClick}
                            name="Reset Table View"
                        />

                    </Grid> */}



                </Grid>
            {/* </Box> */}
            <br></br>

            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title="Reject Claims View"

                    columns={columns}
                    options={options}
                    state={tableState}
                    setState={setTableState}
                    data={chosenRejectCode.length > 0 || (chosenRejectCategory !== null && chosenRejectCategory !== "") ? claimsData : rejectedClaims}
                />
            </ThemeProvider>

            {showEditClaimDialogPage && (
                <EditRejClaimsReview
                    onClose={handleCloseEdit}
                    data={currentlySelectedRow}
                    sessionId={location.state.sessionId}
                    onSubmit={handleEditClaim}
                />
            )}
            {showEditClaimBulkDialogPage && (
                <EditRejClaimsReviewBulkUpdate
                    onClose={handleCloseBulkEdit}
                    data={multipleSelectedRows}
                    sessionId={location.state.sessionId}
                    claimsData={chosenRejectCode.length > 0 || (chosenRejectCategory !== null && chosenRejectCategory !== "") ? claimsData : rejectedClaims}
                    

                />
            )}

            {showBulkUnassignDialogPage && (
                <EditRejClaimsReviewBulkUnassign
                    onClose={handleCloseBulkUnassign}
                    data={multipleSelectedRows}
                    claimsData={chosenRejectCode.length > 0 || (chosenRejectCategory !== null && chosenRejectCategory !== "") ? claimsData : rejectedClaims}

                />
            )}

            <ToastContainer />

        </div>
    );



}

export default RejectClaimReviewSearchResultsTable;
