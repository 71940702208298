import React, { useState, useEffect } from "react";
import { Link, Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import Grid from "@mui/material/Grid";
import WelcomePage from "../container/WelcomePage";
import RejectClaimReviewSearchResultsTable from "../container/RejectClaimReviewSearchResultsTable";
import RejectClaimReview from "../container/RejectClaimReview";
// import RequireAuth from "../auth/RequireAuth";
// import Roles from "../auth/Roles";
// import AccessDeniedPage from "../pages/common/AccessDeniedPage";
// import NotFoundPage from "../pages/common/NotFoundPage";
import Footer from "../container/Footer";
// import LoginPage from "./common/LoginPage"
import Appbar from "../components/Appbar"
import FetchLoginDetails from './common/FetchLoginDetails'
import secureLocalStorage  from  "react-secure-storage";

const RejectedClaimsReviewMainPage = () => {
// const [name, setName] = useState( '')
  const [sessionId, setSessionId] = useState({});
  return (
    <>
      <Router>
        <main>
          <Routes>
            <Route
              path="/:sessionId"
              element={
                <>
                  <Appbar />

                  <WelcomePage setSessionId={setSessionId} />
                  {/* setName={setName} */}
                  <Footer />
                  {/* name={name} */}
                </>
              }
            />
            <Route
              path="/rejectedClaimsReview"
              element={
                <>
                  <Appbar />

                  <RejectClaimReview sessionId={secureLocalStorage.getItem("sessionId")} />

                  <Footer />
                </>
              }
            ></Route>

            <Route
              path="/searchResults"
              element={
                <>
                  <Appbar />

                  <RejectClaimReviewSearchResultsTable />

                  <Footer />
                </>
              }
            ></Route>
            <Route path="/:sessionId" element={<FetchLoginDetails />} />

            {/* <Route path='/home'
              element={
                <>
                  <Appbar />
                  <RequireAuth roles={[Roles.ADMIN]} redirectTo="/denied" >
                    <WelcomePage />
                  </RequireAuth>
                  <Footer />
                </>
              }

            /> */}

            {/* <Route path="denied" element={<AccessDeniedPage />} ></Route> */}
            {/* <Route path="404" element={<NotFoundPage />} /> */}
          </Routes>
        </main>
      </Router>
    </>
  );
};
export default RejectedClaimsReviewMainPage;
