import React, { useState, useEffect } from "react"
import Typography from '@mui/material/Typography'




export default function Account() {

    
    const [name, setName] = useState();

    useEffect(() => {
        setTimeout(() => {
            setName(sessionStorage.getItem('user-name'))
        }, 800)
    }, []);

    console.log("Async User name ", name)

    return (
        <>


            <Typography variant="h6" sx={{ color: 'common.white' }}>Hello {name}</Typography>
        </>
    )
}

