import axios from "axios";

const CARRIER_LIST_URL =  "api/v1/rxview/carriers"
const ACCOUNT_LIST_URL =  "api/v1/rxview/accounts"
const GROUP_LIST_URL =  "api/v1/rxview/groups"

// gets the carriers chosen
export const getCarriersList = async (sessionId, tenantId) => {
  const { data } = await axios.get(CARRIER_LIST_URL, {
    headers: {
      sessionId: sessionId,
      tenantId: tenantId,
    },
  });
   console.log(data);
  return data;
};

// gets the accounts depending on the carrier chosen
export const getAccountsList = async (carrier) => {
  let carrierStr = listToString(carrier)
  const { data } = await axios.get(ACCOUNT_LIST_URL + `?carrierId=${encodeURIComponent(carrierStr)}`) 
  // console.log(data)
  return data
}

// gets the groups depending on account and carrier chosen
export const getGroupsList = async (carrier, account) => {
  let carrierStr = listToString(carrier)
  let accountStr = listToString(account)
  const { data } = await axios.get(GROUP_LIST_URL + `?carrierId=${encodeURIComponent(carrierStr)}` + `&accountId=${encodeURIComponent(accountStr)}`) 
  // console.log(data)
  return data
}

// converts list to string
function listToString(lst){
  let str = ''
  lst.forEach(element => {
    str = str + element.key.trim() + ","
  })
  str = str.slice(0, -1);
  console.log(str)
  return str
}