import Input from "../components/Input"
import DatePicker from "../components/DatePicker"
import Button from "../components/Button"
// import SelectDropDown from "../components/SelectDropDown"
import SearchFilter from "../components/SearchFilter"
import {SelectDropDown, SelectMultiple,SelectSingle} from "../components/SelectDropDown"

const Controls = {
    Input,
    DatePicker,
    Button,
    SelectDropDown,
    SearchFilter,
    SelectMultiple,
    SelectSingle


   

}

export default Controls;    