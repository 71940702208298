import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getCountOfDueDateApproaching } from "../services/RxViewServiceSaveReview";
import FetchLoginDetails from "../pages/common/FetchLoginDetails";

function WelcomePage({ setSessionId }) {
  let { sessionId } = useParams();

  const [dueDateCounter, setDueDateCounter] = useState(0);

  // at load of welcome page, it will display alert if there is any due date approaching for the user logged in
  useEffect(() => {
    setTimeout(() => {
      // get claim summary detail
      var fullName = sessionStorage.getItem("user-fullName")
      fullName = fullName.split(" ")
      var formattedFullName = fullName[1] + ", " + fullName[0]
      getCountOfDueDateApproaching(formattedFullName).then(function (result) {
        setDueDateCounter(result);
        return;
      });
    }, 800)
  }, []);

  useEffect(() => {
    if (dueDateCounter > 0) {
      alert("You have " + dueDateCounter + " claims approaching the due date");
    }
  }, [dueDateCounter]);

  return (
    <div className="home1">
      <br></br>
      <FetchLoginDetails sessionId={sessionId} setSessionId={setSessionId} />
      <h1>Welcome</h1>

      <span>Welcome to Rejected Claims Review application</span>

      <br></br>

      <br></br>

      <span>
        This feature will provide a search mechanism for users to find Rejected
        Claims.{" "}
      </span>

    </div>
  );
}

export default WelcomePage;
