import React from "react";
import {
Link,
Routes,
Route,
BrowserRouter as Router,
useLocation
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';



function Appbar() {

// function changeBackground(e) {
//     e.target.style.background = 'beige';
// }
// function changeBackground2(e) {
//     e.target.style.background = 'orange';
// }

function changeBackground(e) {
    e.target.style.background = "#FBF9F4";
  }

  function changeBackground2(e) {
    e.target.style.background = "#D9F6FA";
  }
return (
    
 <AppBar elevation={0} position="static">
      <Grid container bgcolor="#D9F6FA" sx={{ height: '40px' }}>
        <Grid item xs={8}>
          <Link
            to="/rejectedClaimsReview"
            className="home2"
            onMouseOver={changeBackground}
            onMouseOut={changeBackground2}
          >
            Reject Claim Review
          </Link>
          {/* <Link
            to="/searchResults"
            className="home2"
            onMouseOver={changeBackground}
            onMouseOut={changeBackground2}
          >
            Search Results
          </Link> */}

        </Grid>
      </Grid>
    </AppBar> 
);

}
export default Appbar;
    