import React, { useState, useffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Paper } from "@mui/material";
import { status, action, activity, finalDisposition } from "../components/DropDownData"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
       //   backgroundColor: "#002677",
       backgroundColor: "#EEEEEE",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
        backgroundColor: theme.palette.action.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function getNotesAndCombineStr(props, type){
    const arr = props.claimReviewNotes.filter(x => x.noteType === type)
    var str = ''
    arr.forEach(element => {
        str = str + element.notes
    });
    return str
}

export default function Summary(props) {

    // console.log(props.summaryClaimData.statusCode)
    const statusCodeIndex = typeof props.summaryClaimData === 'object' ? status.findIndex(x => x.val === props.summaryClaimData.statusCode) : null;
    const actionCodeIndex = typeof props.summaryClaimData === 'object' ? action.findIndex(x => x.val === props.summaryClaimData.actionCode) : null;
    const activityCodeIndex = typeof props.summaryClaimData === 'object' ? activity.findIndex(x => x.val === props.summaryClaimData.activityCode) : null;
    const finalDispositionCodeIndex = typeof props.summaryClaimData === 'object' ? finalDisposition.findIndex(x => x.val === props.summaryClaimData.finalDispositionCode) : null;

    const finalDispositionNoteStr = typeof props.claimReviewNotes === 'object' ? getNotesAndCombineStr(props, "F") : null
    const activityNoteStr = typeof props.claimReviewNotes === 'object' ? getNotesAndCombineStr(props, "A") : null

    // var finalDispositionNoteStr = ''
    // if (finalDispositionNote.length > 1){
    //     finalDispositionNote.forEach(element => {
    //         finalDispositionNoteStr = finalDispositionNoteStr + element.notes
    //     });
    // }
    // const activityNote = typeof props.claimReviewNotes === 'object' ? props.claimReviewNotes.filter(x => x.noteType === "A") : null
    // var activityNoteStr = ''
    // if (activityNote.length > 1){
    //     activityNote.forEach(element => {
    //         activityNoteStr = activityNoteStr + element.notes
    //     });
    // }

    function dateConversion(date){
        date = date.toString()
        var century = date.slice(0,1)
        var year = date.slice(1,3)
        var month = date.slice(3,5)
        var day = date.slice(5,7)
        if (century === "1"){
            return month + "/" + day + "/" + "20" + year
        }
        else {
            return month + "/" + day + "/" + "19" + year
        }
    }

    return (
        <div>
        {/* Assignment */}
        <Typography>Assignment</Typography>
        <TableContainer style={{marginBottom: '10px'}} component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Assigned To</StyledTableCell>
                        <StyledTableCell>Due Date</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : props.summaryClaimData.assignTo}</StyledTableCell>
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : dateConversion(props.summaryClaimData.dueDate)}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer> <br></br> <br></br>
        {/* Tracking */}
        <Typography>Tracking</Typography>
        <TableContainer style={{marginBottom: '10px'}} component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Followup Date</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                        <StyledTableCell>Activity</StyledTableCell>
                        <StyledTableCell>Activity Note</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : dateConversion(props.summaryClaimData.followUpDate)}</StyledTableCell>
                        {/* checks if this claim info already exist in table else display dummy value */}
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : statusCodeIndex !== -1 ? status[statusCodeIndex].label : ''}</StyledTableCell>
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : actionCodeIndex !== -1 ? action[actionCodeIndex].label : ''}</StyledTableCell>
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : activityCodeIndex !== -1 ? activity[activityCodeIndex].label : ''}</StyledTableCell>
                        <StyledTableCell>{activityNoteStr}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer> <br></br><br></br>
        {/* Final Disposition */}
        <Typography>Final Disposition</Typography>
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Final Disposition</StyledTableCell>
                        <StyledTableCell>Final Disposition Notes</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        {/* checks if this claim info already exist in table else display dummy value */}
                        <StyledTableCell>{typeof props.summaryClaimData !== 'object' || props.bulkUpdate ? '' : finalDispositionCodeIndex !== -1 ? finalDisposition[finalDispositionCodeIndex].label : ''}</StyledTableCell>
                        <StyledTableCell>{finalDispositionNoteStr}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}
