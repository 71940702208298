// Action
export const action = [
    { label: 'Inform Member of DMR Process', val: '01' },
    { label: 'Request COB review', val: '02' },
    { label: 'Request formulary review', val: '03' },
    { label: 'Request member eligibility review', val: '04' },
    { label: 'Request adjudication/setup review (Note: with ORx would prompt us to create ticket in Service Now)' , val: '05'},
    { label: 'Request operational override', val: '06' },
    { label: 'Request PA (clinical review)', val: '07' },
    { label: 'Request pharmacy network review', val: '08' },
    { label: 'Requires outreach to member', val: '09' },
    { label: 'Requires outreach to pharmacy' , val: '10'},
    { label: 'Requires outreach to prescriber', val: '11' },
];

// Activity
export const activity = [
    { label: 'Outreach attempted (left message)', val: '01' },
    { label: 'Outreach attempted (no answer)' , val: '02'},
    { label: 'Outreach attempted (office closed)', val: '03' },
    { label: 'Outreach attempted (phone note found)' , val: '04'},
    { label: 'Outreach Completed' , val: '05'},
    { label: 'Ticket entered in Service Now', val: '06' },
    { label: 'Ticket closed in Service Now', val: '07' },
];

// Status
export const status = [
    { label: 'In Progress', val: '01'},
    { label: 'Awaiting Response', val: '02' },
    { label: 'Complete', val: '03' },
    { label: 'Cancel', val: '00' },
];

// final disposition
export const finalDisposition = [
    { label: 'No action required - appropriate denial', val: '01'},
    { label: 'Subsequent approved claim', val: '02'},
    { label: 'Subsequent rejected claim', val: '03'},
    { label: 'Drug no longer needed', val: '04'},
    { label: 'Resolved', val: '05'},
    { label: 'No further action required - good faith attempt', val: '06'},
    { label: 'Review not required', val: '07'},
];