const resolvePromise = async promise => {
    const response = {
      data: null,
      error: null
    };
  
    try {
      response.data = await promise;
    } catch (e) {
      response.error = e;
      console.log("response error " + response.error);
    }
    return response.data;
  };
  
  export default resolvePromise;